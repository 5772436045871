import React from "react";
import SelectList from "components/admin/Fields/SelectList";

class CountryGroupSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || props.groups.map((c) => c.id),
    };
  }

  render() {
    const { label, name, groups } = this.props;
    const { selected } = this.state;

    return (
      <div>
        <SelectList
          label={label}
          options={groups}
          selected={selected.map((s) => parseInt(s, 10))}
          onChange={(selected) => this.setState({ selected })}
        />

        <input type="hidden" name={`${name}[]`} value="" />
        {selected
          .map((s) => parseInt(s, 10))
          .filter((s) => s > 0)
          .map((cid) => (
            <input key={cid} type="hidden" name={`${name}[]`} value={cid} />
          ))}
      </div>
    );
  }
}

CountryGroupSelect.defaultProps = {
  label: "Groups",
};

export default CountryGroupSelect;
