import React from "react";
import { RectShape } from "react-placeholder/lib/placeholders";
import { Placeholder, colors } from "components/app/Placeholder";

const Gallery = () => (
  <React.Fragment>
    <div className="gallery-details">
      <header className="header-gallery-article">
        <div className="header-gallery-article-col">
          <div className="previous-gallery">
            <div className="gallery-tooltip-wrapper">
              <span className="icon-arrow-left" />
            </div>
          </div>
          <div className="header-gallery-text">
            <RectShape
              color={colors.darkGray}
              style={{ width: "160px", height: "18px", marginBottom: "0.5em" }}
            />
            <RectShape
              color={colors.darkGray}
              style={{ width: "320px", height: "12px", marginBottom: "0.5em" }}
            />
          </div>
        </div>

        <div className="header-gallery-article-col">
          <div className="header-gallery-right-bar">
            <RectShape
              color={colors.darkGray}
              style={{ width: "240px", height: "36px" }}
            />
          </div>

          <div className="next-gallery">
            <div className="gallery-tooltip-wrapper">
              <span className="icon-arrow-right" />
            </div>
          </div>
        </div>
      </header>

      <div className="gallery-content">
        <div className="container">
          <RectShape
            color={colors.lightBlue}
            style={{ width: "100%", height: "1000px" }}
          />
        </div>
      </div>
    </div>

    <div className="related-galleries">
      <div className="container">
        <div className="section-title-wrapper">
          <h3 className="section-title">Related Projects</h3>
        </div>
      </div>

      <div className="container">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {[1, 2, 3, 4].map((num) => (
            <RectShape
              key={num}
              color={colors.darkGray}
              style={{ height: "180px" }}
            />
          ))}
        </div>
      </div>
    </div>
  </React.Fragment>
);

const GalleryPlaceholder = ({ realComponent, ready }) => (
  <Placeholder
    ready={ready}
    placeholderComponent={Gallery}
    realComponent={realComponent}
  />
);

export default GalleryPlaceholder;
