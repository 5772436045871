import React from "react";
import Tree from "./Tree";
import PagesContext from "./PagesContext";

function BreadcrumbsBuilder({ label, name, value, pages, pages_url }) {
  return (
    <div className="field-pannel form-group">
      <label className="control-label">{label}</label>

      <PagesContext.Provider value={pages}>
        <Tree value={value} name={name} pagesUrl={pages_url} />
      </PagesContext.Provider>
    </div>
  );
}

export default BreadcrumbsBuilder;
