import React from 'react';
import PropTypes from 'prop-types';

function redirectConfirm(path, onConfirm) {
    location.href = path;
}

function redirectCancel(path, onCancle) {
    location.href = path;
}

function ConfirmModal(props) {
  const { title, okButton, cancelButton, onConfirm, onCancel, confirmPath, cancelPath, text } = props;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={() => (redirectCancel(cancelPath))} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" aria-label="close" onClick={() => (redirectCancel(cancelPath))}></button>
        </header>
        <section className="modal-card-body">
        <p>
          {text}
        </p>
        </section>
        {(okButton || cancelButton) &&
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            {cancelButton && <button className="button" onClick={() => (redirectCancel(cancelPath))}>Cancel</button>}
            {okButton && <button className="btn-active" onClick={() => (redirectConfirm(confirmPath))}>OK</button>}
          </footer>
        }
      </div>
    </div>
  );

}

ConfirmModal.defaultProps = {
  title: 'Are you sure?',
  okButton: true,
  cancelButton: true,
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  cancelPath: PropTypes.string,
  confirmPath: PropTypes.string,
  okButton: PropTypes.bool,
  cancelButton: PropTypes.bool,
};

export default ConfirmModal;
