import React, { useState } from "react";
import { List, RaketaUIProvider } from "@raketa-cms/raketa-cms";
import FormField from "./FormField";

export default ({ name, value }) => {
  const [items, setItems] = useState(value);

  return (
    <RaketaUIProvider>
      <div className="field-pannel form-group form-builder">
        <List
          listItem={(settings, onChangeItem) => (
            <FormField
              settings={settings.item}
              onChangeItem={(newItem) => {
                onChangeItem("item", newItem);
                onChangeItem("name", newItem.name);
              }}
            />
          )}
          onChangeList={(_, newItems) =>
            setItems(newItems.map((newItem) => newItem.item))
          }
          items={items.map((item, idx) => ({
            id: idx,
            item: item,
            name: item.name,
          }))}
          primaryField="name"
          template={{
            id: 42,
            name: "first_name",
            item: {
              type: "text",
              name: "first_name",
              label: "First Name",
              placeholder: "Your first name here...",
              hint: "",
              required: true,
              pattern: "",
              character_limit: "",
              options: [],
            },
          }}
        />

        <input type="hidden" name={name} value={JSON.stringify(items)} />
      </div>
    </RaketaUIProvider>
  );
};
