import React from 'react';

class TextInput extends React.Component {
  getValue() {
    return this.refs.input_text.value.trim();
  }

  reset() {
    this.refs.input_text.value = '';
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>

        {this.props.children}

        <input
          className="form-control"
          defaultValue={this.props.value}
          onChange={this.props.onChange}
          ref="input_text"
          placeholder={this.props.placeholder}
          type={this.props.type || 'text'}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default TextInput;
