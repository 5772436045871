/* eslint-disable import/no-unresolved */
import React from "react";
import A from "components/app/A";

export default ({
  navigation,
  products,
  industry,
  author,
  gallerySlug,
  galleryName,
  pagePath,
  onGalleryChange,
  onClose,
}) => (
  <header className="header-gallery-article">
    <div className="header-gallery-article-col">
      <div className="previous-gallery">
        <A
          href={pagePath(`gallery/${navigation.previous.slug}`)}
          className="gallery-tooltip-wrapper"
          onClick={(event) =>
            onGalleryChange && onGalleryChange(event, navigation.previous.slug)
          }
        >
          <span className="icon-arrow-left" />

          <div className="gallery-tooltip">
            <span>
              Previous in
              {` ${industry.name}`}
            </span>
            <br />
            <h6 className="gallery-name">{navigation.previous.name}</h6>
          </div>
        </A>
      </div>

      <div className="header-gallery-text">
        <h1 className="gallery-title">{galleryName}</h1>

        <p className="gallery-meta">
          by &nbsp;
          <A
            href={pagePath(`gallery/profile/${author.slug}`)}
            className="author"
          >
            {author.name}
          </A>
          <A
            href={pagePath(`gallery/industry/${industry.slug}`)}
            className="tag"
          >
            {industry.name}
          </A>
          {products.map((product) => (
            <A
              key={product.id}
              href={pagePath(`gallery/product/${product.slug}`)}
              className="tag"
            >
              {product.name}
            </A>
          ))}
        </p>
      </div>
    </div>

    <div className="header-gallery-article-col">
      <div className="header-gallery-right-bar">
        <div className="social-links">
          <a
            href={`https://facebook.com/sharer/sharer.php?u=${pagePath(
              `gallery/${gallerySlug}`
            )}`}
            className="icon-facebook"
            id="news-share-fb"
            target="_blank"
            rel="noopener noreferrer"
          />
          <a
            href={`https://twitter.com/intent/tweet/?text=&amp;amp;url=${pagePath(
              `gallery/${gallerySlug}`
            )}`}
            className="icon-twitter"
            id="news-share-tw"
            target="_blank"
            rel="noopener noreferrer"
          />
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${pagePath(
              `gallery/${gallerySlug}`
            )}`}
            className="icon-linkedin"
            id="news-share-g"
            target="_blank"
            rel="noopener noreferrer"
          />
          <a
            href={`mailto:?subject=Studio&amp;body=${pagePath(
              `gallery/${gallerySlug}`
            )}`}
            className="icon-mail"
            rel="noopener noreferrer"
          />
        </div>

        <A
          className="icon-close lightbox-close"
          href={pagePath("gallery")}
          onClick={(event) => onClose && onClose(event)}
        />
      </div>

      <div className="next-gallery">
        <A
          href={pagePath(`gallery/${navigation.next.slug}`)}
          className="gallery-tooltip-wrapper"
          onClick={(event) =>
            onGalleryChange && onGalleryChange(event, navigation.next.slug)
          }
        >
          <span className="icon-arrow-right" />

          <div className="gallery-tooltip">
            <span>
              Next in
              {` ${industry.name}`}
            </span>
            <br />
            <h6 className="gallery-name">{navigation.next.name}</h6>
          </div>
        </A>
      </div>
    </div>
  </header>
);
