import React from "react";
import Item from "./Item";

const CheckboxButton = ({ id, checked, onChange }) => (
  <input type="checkbox" id={id} checked={checked} onChange={onChange} />
);

const promoPricesEnabled = (items) => items.some((item) => item.promo_active);
const fetchOverrideCurrency = (items) =>
  items.find((item) => item.override_currency)?.override_currency || "";

const prepItems = (propItems) =>
  propItems && propItems.length > 0 ? [...propItems] : [];

class ItemsSection extends React.Component {
  constructor(props) {
    super(props);

    const items = prepItems(this.props.items);
    const promoActive = promoPricesEnabled(items);
    const overrideCurrency = fetchOverrideCurrency(items);
    const refactoredItems = items.map((item) => ({
      ...item,
      promo_active: promoActive,
      override_currency: overrideCurrency,
    }));

    this.state = {
      randomId: (Math.random() * 100000).toFixed(0),
      items: refactoredItems,
      promoActive: promoActive,
      overrideCurrency: overrideCurrency,
    };

    this.renderItems = this.renderItems.bind(this);
    this.handleNewItem = this.handleNewItem.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleTogglePromoPrices = this.handleTogglePromoPrices.bind(this);
    this.handleOverrideCurrency = this.handleOverrideCurrency.bind(this);
  }

  renderItems = (items, pricingType, namePrefix, labels) =>
    items
      .sort((a, b) => a.min_quantity - b.min_quantity)
      .map((item, idx) => (
        <Item
          key={idx}
          index={idx}
          prefix={`${namePrefix}[${pricingType}]`}
          item={item}
          onDelete={this.handleDeleteItem}
          labels={labels}
        />
      ));

  itemFactory(itemTemplate, pricingType, promoActive) {
    return {
      ...itemTemplate,
      pricing_type: pricingType,
      promo_active: promoActive,
    };
  }

  handleNewItem() {
    const { itemTemplate, pricingType } = this.props;
    const { promoActive } = this.state;
    const newItems = [
      ...this.state.items,
      this.itemFactory(itemTemplate, pricingType, promoActive),
    ];

    this.setState({ items: newItems });
  }

  handleDeleteItem(delIdx) {
    const newItems = this.state.items.filter((_item, idx) => idx !== delIdx);

    this.setState({ items: newItems });
  }

  handleTogglePromoPrices() {
    const promoActive = !this.state.promoActive;
    const newItems = this.state.items.map((item) => ({
      ...item,
      promo_active: promoActive,
    }));

    this.setState({
      items: newItems,
      promoActive: promoActive,
      overrideCurrency: this.state.overrideCurrency,
    });
  }

  handleOverrideCurrency(newValue) {
    const newItems = this.state.items.map((item) => ({
      ...item,
      override_currency: newValue,
    }));

    this.setState({
      items: newItems,
      overrideCurrency: newValue,
      promoActive: this.state.promoActive,
    });
  }

  render() {
    const { pricingType, namePrefix, labels, currencies } = this.props;
    const { items, promoActive, overrideCurrency, randomId } = this.state;

    return (
      <div className="price-type-section">
        <div className="price-type-section-header">
          <h3>{pricingType}</h3>

          <label htmlFor={randomId}>
            <CheckboxButton
              id={randomId}
              checked={promoActive}
              onChange={this.handleTogglePromoPrices}
            />
            Promotion
          </label>

          <div className="form-group">
            <label className="control-label">Override currency</label>
            <select
              className="form-control"
              onChange={(e) => this.handleOverrideCurrency(e.target.value)}
            >
              <option value="">Please select...</option>
              {currencies.map((currency) => (
                <option
                  key={currency}
                  value={currency}
                  selected={overrideCurrency === currency}
                >
                  {currency}
                </option>
              ))}
            </select>
          </div>
        </div>

        {this.renderItems(items, pricingType, namePrefix, labels)}

        <button
          type="button"
          className="btn btn-sm btn-success"
          onClick={this.handleNewItem}
        >
          {labels.add_item_button}
        </button>
      </div>
    );
  }
}

export default ItemsSection;
