import React from 'react';
import A from 'components/app/A';

class RelationBox extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onChange() {
    let optionValue = this.refs.select.value;
    this.props.onChange(optionValue);
  }

  onCreate() {
    let optionName = this.refs.newOption.value.trim();
    this.refs.newOption.value = '';

    if (!optionName) {
      alert('Name should be set');
      return false;
    }

    this.props.onCreate(optionName);
  }

  onCancel() {
    this.props.onCancel();
  }

  isFormVisible() {
    return this.props.selected == '_createNew' ? true : false;
  }

  render() {
    return (
      <div className="relation-box">
        <div className="form-group">
          <select name={ this.props.name } value={ this.props.selected || '_createNew' } onChange={ this.onChange } disabled={ this.isFormVisible() } id={ this.props.id } ref="select" className="form-control">
            <option value="_createNew" key="new">Create new...</option>
            { this.props.options.map((option) =>
                (<option value={ option.id } key={ option.id }>{ option.name }</option>)) }
          </select>
        </div>
        <div className={ this.isFormVisible() ? 'show' : 'hide' }>
          <div className="form-group">
            <input type="text" ref="newOption" placeholder="Name..." className="form-control" />
          </div>
          <div className="btn-toolbar">
            <button type="button" className="btn btn-success" onClick={ this.onCreate }>Create</button>
            <A href="#" className="" onClick={ this.onCancel }>Cancel</A>
          </div>
        </div>
      </div>
    );
  }
}

export default RelationBox;
