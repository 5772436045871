import React from "react";
import { PAGES } from "@chaos/website-frontend";
import PageProvider from "../../cms/PageProvider";

const { DocumentationTest } = PAGES;

const THEMES = [
  ["white", "White"],
  ["light", "Light"],
  ["dark", "Dark"],
];

export default function Widgets({ ...rest }) {
  return (
    <PageProvider {...rest}>
      <DocumentationTest themes={THEMES} />
    </PageProvider>
  );
}
