/* eslint-disable import/no-unresolved */
import React from "react";
import Img from "components/ui/Img";
import Slider from "components/ui/Slider";
import A from "components/app/A";

export default ({ galleries, pagePath, onGalleryChange }) => (
  <div className="related-galleries">
    <div className="container">
      <div className="section-title-wrapper">
        <h3 className="section-title">Related Projects</h3>
      </div>
    </div>

    <div className="container">
      <Slider
        containerClass="related-projects swiper-container"
        rebuildOnUpdate
        pagination={{
          clickable: false,
        }}
        navigation={{
          nextEl: ".swiper-button-next.icon-arrow-right",
          prevEl: ".swiper-button-prev.icon-arrow-left",
        }}
        loop
        breakpoints={{
          599: {
            slidesPerView: "auto",
            slidesPerGroup: 1,
            spaceBetween: 30,
          },
          600: {
            slidesPerView: 5,
            slidesPerGroup: 1,
            spaceBetween: 20,
          },
        }}
      >
        {galleries.map((gallery) => (
          <A
            key={gallery.id}
            className="gallery-thumb article-image"
            href={pagePath(`gallery/${gallery.slug}`)}
            onClick={(event) =>
              onGalleryChange && onGalleryChange(event, gallery.slug)
            }
          >
            <div className="image-container">
              <Img src={gallery.image} variant="articles_2_columns" />
            </div>
            <div className="opacity-overlay">
              <div className="thumb-text">
                <h6 className="thumb-title">{gallery.name}</h6>
                <p className="meta">
                  <span className="name">{gallery.author.name}</span>
                  <span className="category">{gallery.industry.name}</span>
                </p>
              </div>
            </div>
          </A>
        ))}
      </Slider>
    </div>
  </div>
);
