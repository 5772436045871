import React from 'react';

export default () => (
  <div className="markdown-cnt">
    <h5 className="modal-title">Markdown Help</h5>

    <h6><strong>Text format</strong></h6>

    <p>
      **bold**
      <br />
      *italic*
    </p>

    <h6><strong>Paragraph</strong></h6>

    <p>Text on separate newline</p>

    <h6><strong>Lists</strong></h6>

    <ul>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
    </ul>

    <h6><strong>Headings</strong></h6>

    <p>
      # Heading 1
      <br />
      ## Heading 2
      <br />
      ### Heading 3
      <br />
      #### Heading 4
    </p>

    <h6><strong>Link</strong></h6>

    <p>[I&apos;m an inline style link](https://www.google.com)</p>

    <h6><strong>Image</strong></h6>

    ![alt text](/path/to/image.png)
  </div>
);
