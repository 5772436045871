import React from "react";

import PageProvider from "components/pages/PageProvider";
import { GalleryContent } from "components/app/pages/gallery";
import { PageUrlConsumer } from "@chaos/website-frontend";

const hostUrl = (url) => {
  const newUrl = new URL(url);

  return newUrl.origin;
};

export default (props) => {
  const { component_props, ...rest } = props;
  const { gallery, navigation, related_galleries } = component_props;

  return (
    <PageProvider {...rest}>
      <PageUrlConsumer>
        {(currentUrl) => (
          <GalleryContent
            gallery={gallery}
            navigation={navigation}
            relatedGalleries={related_galleries}
            hostUrl={hostUrl(currentUrl)}
          />
        )}
      </PageUrlConsumer>
    </PageProvider>
  );
};
