import React from "react";
import { Input, Textarea } from "./Input";
import ButtonInput from "./ButtonInput";
import {
  ImagePicker,
  MediaManagerContext,
} from "@raketa-cms/raketa-image-picker";
import { RaketaUIProvider, SelectMenu, List } from "@raketa-cms/raketa-cms";
import MediaManager from "components/cms/MediaManager";
import SelectList from "components/admin/Fields/SelectList";

const mediaManager = new MediaManager("/");

const LocationBasedDescription = ({
  settings,
  onChangeItem,
  countryGroups,
  countries,
}) => {
  return (
    <div>
      <Textarea
        value={settings.description || ""}
        label="Description"
        onChange={(val) => onChangeItem("description", val)}
      />

      <SelectMenu
        label="GeoIp enabled"
        options={[
          ["yes", "Yes"],
          ["no", "No"],
        ]}
        value={settings.geoip}
        onChange={(value) => onChangeItem("geoip", value)}
      />

      {settings.geoip === "yes" && (
        <>
          <SelectList
            label="Country groups"
            options={countryGroups}
            selected={(settings.country_groups || []).map((s) =>
              Number.parseInt(s, 10)
            )}
            onChange={(value) => onChangeItem("country_groups", value)}
          />

          <SelectList
            label="Countries"
            options={countries}
            selected={settings.countries || []}
            onChange={(value) => onChangeItem("countries", value)}
          />
        </>
      )}
    </div>
  );
};

const ProductInput = ({ label, value, onChange, countries, countryGroups }) => {
  function updateValue(key, val) {
    onChange({
      ...value,
      [key]: val,
    });
  }

  return (
    <>
      <h4>{label}</h4>
      <Input
        type="text"
        value={value.rate_plan_id || ""}
        label="Cross-sell Product (Rate Plan ID)"
        onChange={(val) => updateValue("rate_plan_id", val)}
      />

      <Input
        type="text"
        value={value.name || ""}
        label="Name"
        onChange={(val) => updateValue("name", val)}
      />

      <RaketaUIProvider>
        <MediaManagerContext.Provider value={mediaManager}>
          <ImagePicker
            onChange={(val) => updateValue("logo", val)}
            value={value.logo}
          />
        </MediaManagerContext.Provider>
      </RaketaUIProvider>

      <Textarea
        value={value.description || ""}
        label="Description"
        onChange={(val) => updateValue("description", val)}
      />

      <RaketaUIProvider>
        <List
          label="Localized descriptions"
          listItem={(settings, onChangeItem) => (
            <LocationBasedDescription
              settings={settings}
              onChangeItem={onChangeItem}
              countryGroups={countryGroups}
              countries={countries}
            />
          )}
          onChangeList={(_, val) => updateValue("secondary_descriptions", val)}
          items={value.secondary_descriptions || []}
          primaryField="description"
          template={{
            description: "",
            geoip: false,
            countries: [],
            country_groups: [],
          }}
        />
      </RaketaUIProvider>

      <Input
        type="text"
        value={value.price_label || ""}
        label="Price label"
        onChange={(val) => updateValue("price_label", val)}
      />

      <ButtonInput
        label="Upsell Button"
        value={value.button || {}}
        onChange={(val) => updateValue("button", val)}
      />

      <ButtonInput
        label="Upsell Button (added)"
        value={value.button_added || {}}
        hint="The button label supports the {CHECK} token"
        onChange={(val) => updateValue("button_added", val)}
      />
    </>
  );
};

const CrossSellSectionInput = ({ value, name, countries, country_groups }) => {
  const [state, setState] = React.useState(value);

  function updateState(key, val) {
    setState((currrent) => ({
      ...currrent,
      [key]: val,
    }));
  }

  return (
    <div>
      <Input
        type="text"
        value={state.title || ""}
        label="Section Title"
        hint="Supports markdown"
        onChange={(val) => updateState("title", val)}
      />

      <Input
        type="text"
        value={state.sub_title || ""}
        label="Section sub-ttitle"
        hint="Supports markdown"
        onChange={(val) => updateState("sub_title", val)}
      />

      <ButtonInput
        label="Continue Button"
        value={state.continue_button || {}}
        onChange={(val) => updateState("continue_button", val)}
        hint="If the label field is blank, the button will not be visible and it will be mandatory to pick 1 roduct (not possible to pick 2)"
      />

      <ProductInput
        label="Product 1"
        value={state.product_1 || {}}
        onChange={(val) => updateState("product_1", val)}
        countries={countries}
        countryGroups={country_groups}
      />

      <ProductInput
        label="Product 2"
        value={state.product_2 || {}}
        onChange={(val) => updateState("product_2", val)}
      />

      <input
        className="form-control"
        type={"hidden"}
        name={name}
        defaultValue={JSON.stringify(state)}
      />
    </div>
  );
};

export default CrossSellSectionInput;
