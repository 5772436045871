import React from "react";
import { HubspotPopup } from "@chaos/website-frontend";

const setupPopupCookie = (list_ids) => {
  const filtered_ids = [...new Set(list_ids)];

  const d = new Date();

  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 395);

  document.cookie = `list_id=${filtered_ids.join(
    ","
  )}; path=/; expires=${d.toGMTString()}; SameSite=None; Secure`;
};

const fetchMemberships = (user, popups) => {
  const userLists = (user && user["list-memberships"]) || [];
  const userListIds = userLists.map((list) => list["static-list-id"]) || [];

  return popups.filter((p) =>
    userListIds.includes(p.popup?.hubspot_list?.list_id?.id)
  );
};

const extractPopup = (user, popups, popupCookieIDs) => {
  const userMembershipPopups = fetchMemberships(user, popups);
  const popupsWithNoList = popups.filter(
    (p) => !p.popup?.hubspot_list?.list_id?.id
  );

  const activePopup = userMembershipPopups
    .concat(popupsWithNoList)
    .filter(
      (p) => !popupCookieIDs.includes(p.popup.id) && p.popup.active === "yes"
    )[0];

  const { popup } = activePopup || {};

  return popup;
};

const useHubspotPopups = (popups) => {
  const [user, setUser] = React.useState(null);
  const [popupCookieIDs, setPopupCookieIDs] = React.useState([]);
  const [openPopup, setOpenPopup] = React.useState(true);
  const [isSettingCookie, setIsSettingCookie] = React.useState(true);

  React.useEffect(() => {
    const huspotUtk =
      document.cookie
        .match("(^|;)\\s*" + "hubspotutk" + "\\s*=\\s*([^;]+)")
        ?.pop() || "";

    const cookieListIds =
      document.cookie
        .match("(^|;)\\s*" + "list_id" + "\\s*=\\s*([^;]+)")
        ?.pop()
        .split(",") || [];

    setPopupCookieIDs(cookieListIds);

    if (huspotUtk) {
      fetch(`/api/hubspot/contacts/${huspotUtk}`)
        .then((r) => r.json())
        .then((data) => {
          setUser(data);
          setIsSettingCookie(false);
        });
    } else {
      console.warn("Hubspot popups: Hubspot cookie is not present");
    }
  }, []);

  React.useEffect(() => {
    if (isSettingCookie) return;

    setupPopupCookie(popupCookieIDs);
  }, [popupCookieIDs]);

  const popup = user && extractPopup(user, popups, popupCookieIDs);
  const popupData = openPopup && popup && user ? popup : null;

  let onClose = null;

  if (popupData) {
    onClose = () => {
      setPopupCookieIDs([...popupCookieIDs, popupData.id]);
      setOpenPopup(false);
    };
  }

  return {
    popup: popupData,
    onPopupClose: onClose,
  };
};

const HubspotPopups = ({ popups }) => {
  const { popup, onPopupClose } = useHubspotPopups(popups);

  return (
    <React.Fragment>
      {popup && (
        <div data-hubspot-popup>
          <HubspotPopup popup={popup} onClose={onPopupClose} />
        </div>
      )}
    </React.Fragment>
  );
};

export default HubspotPopups;
