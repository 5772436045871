import React from "react";
import { TextInput, RaketaUIProvider } from "@raketa-cms/raketa-cms";

const PopupTranslation = ({ value: settings, onChange }) => {
  const onUpdate = (key, value) => {
    onChange({ ...settings, [key]: value });
  };

  return (
    <RaketaUIProvider>
      <TextInput
        label="Link Label"
        onChange={(value) => onUpdate("link_label", value)}
        value={settings.link_label}
        hint="Text should be in the target language."
      />
    </RaketaUIProvider>
  );
};

const LanguagePopupEditor = ({ value, name, label, languages }) => {
  const [state, setState] = React.useState(value ? value : {});

  return (
    <div className="language-popup-editor">
      {languages.map((language) => (
        <div className="item" key={language.code}>
          <label className="control-label">{label}</label>

          <div className="field-pannel form-group">
            <label className="control-label">
              <p
                className="privacy"
                dangerouslySetInnerHTML={{ __html: language.label }}
              />
            </label>

            <PopupTranslation
              value={state[language.code] || {}}
              onChange={(newValue) =>
                setState({ ...state, [language.code]: newValue })
              }
            />

            <input type="hidden" name={name} value={JSON.stringify(state)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LanguagePopupEditor;
