import React from 'react';
import FieldsTextInput from 'components/shared/Fields/TextInput/TextInput';

const TitleField = ({ data, onChange }) => (
  <div>
    <h4>Section Title</h4>
    <div className="fields-wrapper">
      <FieldsTextInput label="Title" onChange={ (e) => onChange('title', e.target.value) } value={ data.title } />
    </div>
  </div>
);

export default TitleField;
