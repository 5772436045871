import React, { useState } from "react";
import { ListInput, SelectMenu, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { AdminRTE } from "@chaos/website-frontend";
import MediaManager from "components/cms/MediaManager";

const mediaManager = new MediaManager("/");

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <AdminRTE
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <SelectMenu
      label="Tooltip"
      options={[
        ["yes", "Yes"],
        ["no", "No"],
      ]}
      value={settings.tooltip}
      onChange={(value) => onChangeItem("tooltip", value)}
    />

    <TextInput
      label="Custom tooltip title"
      onChange={(value) => onChangeItem("custom_tooltip_title", value)}
      value={settings.custom_tooltip_title}
    />

    <AdminRTE
      label="Custom tooltip description"
      onChange={(value) => onChangeItem("custom_tooltip_description", value)}
      value={settings.custom_tooltip_description}
    />

    <ImagePicker
      label="Custom tooltip icon"
      onChange={(value) => onChangeItem("custom_tooltip_icon", value)}
      value={settings.custom_tooltip_icon}
      mediaManager={mediaManager}
    />
  </div>
);

const PricingFeaturesEditor = ({ value, name, label }) => {
  const [items, setItems] = useState(value);

  return (
    <div className="field-pannel form-group">
      <ListInput
        label={label}
        listItem={(settings, onChangeItem, idx) => (
          <ListItem idx={idx} settings={settings} onChangeItem={onChangeItem} />
        )}
        primaryField="description"
        onChangeList={(_, newItems) => setItems(newItems)}
        items={items}
        template={{ id: "", description: "", tooltip: "no" }}
      />

      <input type="hidden" name={name} value={JSON.stringify(items)} />
    </div>
  );
};

export default PricingFeaturesEditor;
