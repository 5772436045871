import React from "react";
import { List, RaketaUIProvider } from "@raketa-cms/raketa-cms";
import { MediaManagerContext } from "@raketa-cms/raketa-image-picker";
import PopupInput from "./PopupInput";
import MediaManager from "components/cms/MediaManager";

const mediaManager = new MediaManager("/");

const TEMPLATE = {
  popup: PopupInput.defaults,
};

const ListItem = ({ settings, onChangeItem, options }) => (
  <div>
    <PopupInput
      options={options}
      onChange={(value) => onChangeItem("popup", value)}
      value={settings.popup}
    />
  </div>
);

const PopupsInput = ({ value, name, options }) => {
  const [state, setState] = React.useState(value ? value : {});

  return (
    <RaketaUIProvider>
      <MediaManagerContext.Provider value={mediaManager}>
        <List
          label="Hubspot/Non hubspot popups"
          listItem={(settings, onChangeItem) => (
            <ListItem
              settings={settings}
              onChangeItem={onChangeItem}
              options={options}
            />
          )}
          items={value}
          template={TEMPLATE}
          onChangeList={(_list, value) => setState(value)}
          primaryField="popup.id"
        />
      </MediaManagerContext.Provider>

      <input type="hidden" name={name} value={JSON.stringify(state)} />
    </RaketaUIProvider>
  );
};

export default PopupsInput;
