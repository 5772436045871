import React from 'react';
import jQuery from 'jquery';
import Cookie from 'Cookie';

const RadioButton = ({ name, value, children, onClick, checked }) => (
  <label className="radio-style">
    <input type="radio" name={name} value={value} checked={checked} onClick={e => onClick(e.target.value)} />
    <span className="label-radio">
      <span className="label-text">{children}</span>
    </span>
  </label>
);

class Vote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      previousVote: props.vote,
      vote: props.vote,
      selectedSurveyAnswerId: 0,
      showSurveyAnswers: false,
      isDownvoteSent: false,
    };
  }

  getArticleUID() {
    const { article } = this.props;
    return `vote_${article.id}`;
  }

  handleVote(vote) {
    const { vote_url } = this.props;
    const { previousVote, selectedSurveyAnswerId } = this.state;

    jQuery.ajax({
      method: 'POST',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      url: vote_url,
      data: { vote, previous_vote: previousVote, reason: selectedSurveyAnswerId },
      dataType: 'json',
      success: () => {
        Cookie.setCookie(this.getArticleUID(), vote);

        this.setState({
          vote,
          previousVote: vote,
          isDownvoteSent: vote === 'down',
        });
      },
    });
  }

  render() {
    const { vote, selectedSurveyAnswerId, showSurveyAnswers, isDownvoteSent } = this.state;
    const { survey_answers } = this.props;

    return (
      <div>
        {!showSurveyAnswers ?
          <div>
            <button
              type="button"
              className={`btn-passive btn-small ${vote === 'up' ? 'btn-pushed' : ''}`}
              disabled={vote === 'up'}
              onClick={() => this.handleVote('up')}
            >Yes</button>

            <button
              type="button"
              className={`btn-passive btn-small ${vote === 'down' ? 'btn-pushed' : ''}`}
              disabled={vote === 'down'}
              onClick={() => this.setState({ vote: 'down', showSurveyAnswers: true })}
            >No</button>
          </div>
          :
          <div>
            {!isDownvoteSent ?
              <div>
                <p>Help us improve this article by sharing your feedback below.</p>
                <ul>
                  {survey_answers.map((surveyAnswer, idx) =>
                    <li key={surveyAnswer.id}>
                      <RadioButton
                        name={surveyAnswer.answer}
                        value={surveyAnswer.id}
                        onClick={() => this.setState({ selectedSurveyAnswerId: surveyAnswer.id })}
                        checked={selectedSurveyAnswerId === idx + 1}
                      >{surveyAnswer.answer}</RadioButton>
                    </li>)}
                </ul>

                <button
                  type="button"
                  className="btn-passive btn-small btn-pushed"
                  onClick={() => this.handleVote('down')}
                >Submit</button>

                <button
                  type="button"
                  className="btn-passive btn-small"
                  onClick={() => this.setState({ showSurveyAnswers: false, vote: 0, selectedSurveyAnswerId: 0 })}
                >Cancel</button>
              </div>
              :
              <p>Thank you for your feedback!</p>
            }

          </div>
        }
      </div>
    );
  }
}

export default Vote;
