import React from "react";
import FieldsTextInput from "components/shared/Fields/TextInput/TextInput";

const PageResult = ({ page, onClick }) => (
  <div className="page-result" onClick={() => onClick(page)}>
    {page.name}
  </div>
);

const PageResults = ({ pages, onClick }) => {
  if (pages.length == 0) return <div></div>;

  return (
    <div className="page-results">
      {pages.map((page) => (
        <PageResult key={page.id} page={page} onClick={onClick} />
      ))}
    </div>
  );
};

const PagePreview = ({ page, onClear }) => {
  if (page.name == "") {
    return (
      <div className="page-preview text-muted empty-state">
        <strong>No page attached</strong>
        <br />
        <span>Use the search above to select a page. </span>
      </div>
    );
  }

  return (
    <div className="page-preview">
      <span className="clear-page" onClick={onClear}>
        &times;
      </span>

      <strong>{page.name}</strong>
      <br />
      <span className="text-muted">{page.slug}</span>
    </div>
  );
};

function defaultPage() {
  return { id: "", name: "", slug: "" };
}

const PagePicker = ({ label, page, search_url, field_name, onSelect }) => {
  const [state, setState] = React.useState({
    query: "",
    pageResults: [],
    page: page || defaultPage(),
  });

  const handleSearch = async (query) => {
    if (query.length > 2) {
      fetch(
        `${search_url}?${new URLSearchParams({
          "f[name]": query,
        }).toString()}`,
        {
          method: "GET",
        }
      )
        .then((r) => r.json())
        .then((d) =>
          setState((current) => {
            return {
              ...current,
              pageResults: d,
            };
          })
        );
    }
  };

  const handleSelectPage = (page) => {
    setState((current) => {
      return {
        ...current,
        page: page,
        query: "",
        pageResults: [],
      };
    });

    if (onSelect) {
      onSelect(page);
    }
  };

  const handleClear = () => {
    if (confirm("Are you sure? ")) {
      setState((current) => {
        return {
          ...current,
          page: defaultPage(),
        };
      });

      if (onSelect) {
        onSelect(defaultPage());
      }
    }
  };

  return (
    <div className="page-picker">
      <FieldsTextInput
        label={label || "Page"}
        placeholder="Search pages..."
        value={state.query}
        onChange={(e) => handleSearch(e.target.value)}
      />

      <PageResults
        pages={state.pageResults}
        onClick={(page) => handleSelectPage(page)}
      />

      <PagePreview page={state.page} onClear={() => handleClear()} />

      {field_name && (
        <input type="hidden" name={field_name} value={state.page.slug} />
      )}
    </div>
  );
};

export default PagePicker;
