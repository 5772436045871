import React from "react";
import { Combobox } from "@headlessui/react";

function Autocomplete({ value, onChange, options }) {
  const [selectedPerson, setSelectedPerson] = React.useState(value);
  const [query, setQuery] = React.useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  React.useEffect(() => {
    onChange(selectedPerson);
  }, [selectedPerson]);

  return (
    <Combobox value={selectedPerson.name} onChange={setSelectedPerson}>
      <div className="combobox">
        <Combobox.Input
          onChange={(event) => setQuery(event.target.value)}
          className="autocomplete"
        />
        <Combobox.Options className="autocomplete-list">
          {filteredOptions.map((option, idx) => (
            <Combobox.Option
              key={idx}
              value={{ id: option.listId, name: option.name }}
              className="autocomplete-option"
            >
              {option.name} ({option.listId})
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}

export default Autocomplete;
