import React from 'react';
import Autocomplete from 'react-autocomplete';
import A from 'components/app/A';

class AutocompleteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      result: [],
      selectedItems: props.items,
      q: '',
    };
  }

  handleSearch(q) {
    const { search_path, searchByKey } = this.props;

    this.setState({ q, result: [] }, () => {
      if (q.length < 3) return;
      $.get(search_path, { f: { [searchByKey]: q } }, result => this.setState({ result }), 'json');
    });
  }

  handleAdd(itemName) {
    const { result, selectedItems } = this.state;
    const { searchByKey } = this.props;

    const newItem = result.find(item => item[searchByKey] === itemName);
    if (selectedItems.some(item => item.id === newItem.id)) return;

    this.setState({ selectedItems: [...selectedItems, newItem], q: '' });
  }

  handlePage(e, itemId) {
    e.preventDefault();
    if (!confirm('Are you sure? ')) return;

    const { selectedItems } = this.state;
    const idx = selectedItems.findIndex(item => item.id === itemId);
    const newItems = [
      ...selectedItems.slice(0, idx),
      ...selectedItems.slice(idx + 1),
    ];

    this.setState({ selectedItems: newItems });
  }

  renderRlatedItems() {
    const { selectedItems } = this.state;

    if (selectedItems.length === 0) return <p className="no-articles-message">No items.</p>;

    const { name, searchByKey } = this.props;

    const rows = selectedItems.map(item => (
      <tr key={item.id}>
        <td>{item[searchByKey]}</td>
        <td className="actions">
          <A onClick={e => this.handlePage(e, item.id)} href="#"><span className="glyphicon glyphicon-trash" /></A>
          <input type="hidden" name={name} value={item.id} />
        </td>
      </tr>
    ));

    return (
      <table className="articles-table table table-condensed table-bordered">
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }

  render() {
    const { name, label, searchByKey } = this.props;
    const { result, q } = this.state;

    return (
      <div className="articles-autocomplete">
        {label && <label className="control-label">{label}</label>}

        <div className="autocomplete">
          <Autocomplete
            getItemValue={item => item[searchByKey]}
            items={result}
            renderItem={(item, isHighlighted) =>
              <div key={item.id} style={{ padding: '.5em', cursor: 'pointer', background: isHighlighted ? 'lightgray' : 'white' }}>
                {item[searchByKey]}
              </div>
            }
            value={q}
            onChange={e => this.handleSearch(e.target.value)}
            onSelect={itemName => this.handleAdd(itemName)}
            menuStyle={{
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              background: '#fff',
              position: 'absolute',
              top: 'auto',
              bottom: '36px',
              overflow: 'auto',
              left: '0',
            }}
          />
        </div>

        <input type="hidden" name={name} value="" />

        {this.renderRlatedItems()}
      </div>
    );
  }
}

AutocompleteList.defaultProps = {
  label: '',
};

export default AutocompleteList;
