import React from "react";
import A from "components/app/A";

const NumericPicker = ({ value, onChange }) => (
  <span className="numeric-picker">
    <A
      href="#"
      className="minus"
      onClick={(e) => {
        e.preventDefault();
        onChange(value <= 0 ? 0 : parseInt(value - 1, 10));
      }}
    >
      –
    </A>

    <input
      type="text"
      value={value}
      onChange={(e) => onChange(parseInt(e.target.value, 10))}
    />

    <A
      href="#"
      className="plus"
      onClick={(e) => {
        e.preventDefault();
        onChange(parseInt(value + 1, 10));
      }}
    >
      +
    </A>
  </span>
);

NumericPicker.defaultProps = {
  onChange: () => "",
};

export default NumericPicker;
