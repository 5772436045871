import React from "react";
import { ListInput, LinkSettings, SelectMenu } from "@raketa-cms/raketa-cms";
import {
  ImagePicker,
  MediaManagerContext,
} from "@raketa-cms/raketa-image-picker";
import MediaManager from "components/cms/MediaManager";
import SelectList from "components/admin/Fields/SelectList";

const mediaManager = new MediaManager("/");

const template = () => ({
  id: 1,
  link: LinkSettings.defaults,
});

const MenuItem = ({ settings, countries, countryGroups, onChangeItem }) => (
  <div>
    <SelectMenu
      label="GeoIp enabled"
      options={[
        ["yes", "Yes"],
        ["no", "No"],
      ]}
      value={settings.geoip}
      onChange={(value) => onChangeItem("geoip", value)}
    />

    {settings.geoip === "yes" && (
      <>
        <SelectList
          label="Country groups"
          options={countryGroups}
          selected={(settings.country_groups || []).map((s) =>
            Number.parseInt(s, 10)
          )}
          onChange={(value) => onChangeItem("country_groups", value)}
        />

        <SelectList
          label="Countries"
          options={countries}
          selected={settings.countries || []}
          onChange={(value) => onChangeItem("countries", value)}
        />
      </>
    )}

    <ImagePicker
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

const AccountMenuEditor = ({
  name,
  label,
  value,
  countries = [],
  country_groups = [],
}) => {
  const [items, setItems] = React.useState(value.length > 0 ? value : []);

  return (
    <MediaManagerContext.Provider value={mediaManager}>
      <div className="field-pannel form-group">
        {/* <label className="control-label">{label}</label> */}
        <ListInput
          label={label}
          listItem={(settings, onChangeItem) => (
            <MenuItem
              settings={settings}
              onChangeItem={onChangeItem}
              countries={countries}
              countryGroups={country_groups}
            />
          )}
          primaryField="link.label"
          onChangeList={(_, newItems) => setItems(newItems)}
          items={items}
          template={template()}
        />

        <input type="hidden" name={name} value={JSON.stringify(items)} />
      </div>
    </MediaManagerContext.Provider>
  );
};

AccountMenuEditor.defaultProps = {
  label: "Menu items",
};

export default AccountMenuEditor;
