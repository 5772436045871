import React from "react";
import SelectList from "components/admin/Fields/SelectList";

const MagazineCategorySelect = ({ selected, categories, label, name }) => {
  const [current, setCurrent] = React.useState(
    selected || categories.map((c) => c.id)
  );

  return (
    <div>
      <SelectList
        label={label}
        options={categories}
        selected={current.map((s) => parseInt(s, 10)).filter((num) => num > 0)}
        onChange={(newSelected) => setCurrent(newSelected)}
      />

      <input type="hidden" name={`${name}[]`} value="" />

      {current.map((cid) => (
        <input key={cid} type="hidden" name={`${name}[]`} value={cid} />
      ))}
    </div>
  );
};

MagazineCategorySelect.defaultProps = {
  label: "Blog categories",
};

export default MagazineCategorySelect;
