import jQuery from 'jquery';

var GalleryImageManager = {
  create: function (data, url, callback) {
    var formData = new FormData();
    formData.append('gallery_image[title]', data.title);
    formData.append('gallery_image[gallery_id]', data.library_id);
    formData.append('gallery_image[image]', data.file);

    jQuery.ajax({
      url: url,
      data: formData,
      dataType: 'json',
      type: 'POST',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      processData: false,
      contentType: false,
      success: function (image) {
        callback(image);
      }
    });
  },

  update: function (data, url, callback) {
    jQuery.ajax({
      url: url,
      type: 'PATCH',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      data: {image: data},
      dataType: 'json',
      success: function (image) {
        callback(image);
      }
    });
  },

  destroy: function (url, callback) {
    jQuery.ajax({
      url: url,
      type: 'DELETE',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      dataType: 'json',
      success: function (image) {
        callback(image);
      }
    });
  },

  all: function (url, params, callback) {
    jQuery.ajax({
      url,
      type: 'GET',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      data: params,
      dataType: 'json',
      success: (images) => callback(images),
    });
  }
};

export default GalleryImageManager;
