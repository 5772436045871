import React from 'react';

class SelectList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || [],
    };
  }

  notifyChange() {
    if (this.props.onChange) this.props.onChange(this.state.selected);
  }

  handleChange(id, e) {
    const checked = e.target.checked;
    const { selected } = this.state;
    let newSelected = [];

    if (checked) {
      newSelected = [
        ...selected,
        id,
      ];
    } else {
      const idx = selected.findIndex(c => c === id);

      newSelected = [
        ...selected.slice(0, idx),
        ...selected.slice(idx + 1),
      ];
    }

    this.setState({ selected: newSelected }, () => this.notifyChange());
  }

  handleSelectAll() {
    const { options } = this.props;
    this.setState({ selected: options.map(c => c.id) }, () => this.notifyChange());
  }

  handleSelectNone() {
    this.setState({ selected: [] }, () => this.notifyChange());
  }

  render() {
    const { options, label } = this.props;
    const { selected } = this.state;

    return (
      <div className="country-list">
        <label>{label}</label>

        <small className="toolbar">
          <button type="button" onClick={() => this.handleSelectAll()}>All</button>
          <button type="button" onClick={() => this.handleSelectNone()}>None</button>
        </small>

        <div className="checkbox-group">
          {options.map(c => (
            <label key={c.id}>
              <input type="checkbox" checked={selected.includes(c.id)} onChange={e => this.handleChange(c.id, e)} /> {c.name}
            </label>
          ))}
        </div>
      </div>
    );
  }
}

SelectList.defaultProps = {
  selected: [],
};

export default SelectList;
