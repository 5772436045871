import React from "react";
import { LinkSettings, RaketaUIProvider } from "@raketa-cms/raketa-cms";

const LinkEditor = ({ value, label, name, hint }) => {
  const [link, setLink] = React.useState(value ? value : LinkSettings.defaults);

  return (
    <RaketaUIProvider>
      <div className="field-pannel form-group">
        <LinkSettings
          label={label}
          onChange={(value) => setLink(value)}
          value={link}
        />

        {hint && (
          <div
            className="help-block"
            dangerouslySetInnerHTML={{ __html: hint }}
          />
        )}

        <input type="hidden" name={name} value={JSON.stringify(link)} />
      </div>
    </RaketaUIProvider>
  );
};

LinkEditor.defaultProps = {
  label: "Link",
};

export default LinkEditor;
