import React from 'react';
import FieldsTextInput from 'components/shared/Fields/TextInput/TextInput';
import FieldsSelectMenu from 'components/admin/Fields/SelectMenu/SelectMenu';

const TextAreaField = ({ data, onChange, onDelete }) => (
  <div>
    <h4>Textarea</h4>
    <div className="fields-wrapper">
      <FieldsTextInput label="Name" onChange={ (e) => onChange('name', e.target.value) } value={ data.name } />
      <FieldsTextInput label="Label" onChange={ (e) => onChange('label', e.target.value) } value={ data.label } />
      <FieldsTextInput label="Placeholder" onChange={ (e) => onChange('placeholder', e.target.value) } value={ data.placeholder } />
    </div>
    <br />

    <FieldsSelectMenu
      label="Required?"
      onChange={(e) => onChange('required', e.target.value)}
      value={data.required}
      options={[ ['yes', 'Yes'], ['no', 'No'] ]}
    />
  </div>
);

export default TextAreaField;
