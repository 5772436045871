import React from "react";
import { Webshop, cartOpen } from "webshop";
import IntentToLeavePopup from "./IntentToLeave";

const WEBSHOP_ENV_MAPPING = {
  development: "dev",
  staging: "staging",
  production: "production",
};

const Cart = ({ show_promo_field, language, env, intent_to_leave }) => {
  return (
    <>
      <Webshop
        language={language}
        env={WEBSHOP_ENV_MAPPING[env]}
        theme="chaos"
        showPromoField={show_promo_field}
      />

      {intent_to_leave.active && (
        <IntentToLeavePopup
          settings={intent_to_leave}
          onAccept={() => {
            cartOpen(true);
          }}
        />
      )}
    </>
  );
};

export default Cart;
