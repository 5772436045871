import React from "react";

const Input = ({ label, type, value, hint = "", onChange }) => (
  <div className="form-group">
    {label && <label className="control-label">{label}</label>}
    <input
      className="form-control"
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />

    {hint && (
      <React.Fragment>
        <div
          className="help-block"
          dangerouslySetInnerHTML={{ __html: hint }}
        />
        <br />
      </React.Fragment>
    )}
  </div>
);

const Textarea = ({ label, value, hint = "", onChange }) => (
  <div className="form-group">
    {label && <label className="control-label">{label}</label>}
    <textarea
      className="form-control"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />

    {hint && (
      <React.Fragment>
        <div
          className="help-block"
          dangerouslySetInnerHTML={{ __html: hint }}
        />
        <br />
      </React.Fragment>
    )}
  </div>
);

export { Input, Textarea };
