import React from "react";
import jQuery from "jquery";
import { PageBuilder } from "@raketa-cms/raketa-cms";
import { MediaManagerContext } from "@raketa-cms/raketa-image-picker";
import MediaManager from "./MediaManager";
import { ADMIN_LIBRARY, LIBRARY } from "@chaos/website-frontend";
import PageProvider from "./PageProvider";
import GalleryLayout from "./GalleryLayout";

const THEMES = [
  ["white", "White"],
  ["light", "Light"],
  ["dark", "Dark"],
];

const SPACINGS = [
  ["top", "Top"],
  ["bottom", "Bottom"],
  ["both", "Both"],
  ["top-lg", "Top (large)"],
  ["bottom-lg", "Bottom (large)"],
  ["both-lg", "Both (large)"],
];

const mediaManager = new MediaManager("/");

class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { children } = this.props;
    return <React.Fragment>{children}</React.Fragment>;
  }
}

class AdminBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
      page: props.page,
    };
  }

  componentDidMount() {
    // Check for changes and warn user
    jQuery(window).bind("beforeunload", () => {
      if (this.state.dirty) return "You have unsaved changes";
    });
  }

  componentDidCatch(error, info) {
    console.log("error", error, info);
  }

  handleChange() {
    this.setState({ dirty: true });
  }

  handleSave(page) {
    const { save_url } = this.props;

    jQuery.ajax({
      method: "PATCH",
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          jQuery('meta[name="csrf-token"]').attr("content")
        );
      },
      contentType: "application/json; charset=utf-8",
      url: save_url,
      data: JSON.stringify({ widgets: page.widgets }),
      dataType: "json",
      success: (resp) => {
        this.setState({ dirty: false });
      },
      error: (xhr, err, e) => {
        alert("An error occured saving this page. ");
      },
    });
  }

  handlePageRedirect(pagePath, action) {
    const pagePreviewPath = `${window.location.origin}${pagePath}`;

    action === "preview"
      ? window.open(pagePreviewPath)
      : (window.location.href = pagePreviewPath);
  }

  customButtons(page_path, publish_path) {
    const buttons = [];

    if (page_path) {
      buttons.push({
        id: "preview",
        label: "Preview",
        className: "cms-preview-btn",
        onClick: () => this.handlePageRedirect(page_path, "preview"),
      });
    }

    if (publish_path) {
      buttons.push({
        id: "publish",
        label: "Publish",
        className: "cms-publush-btn",
        onClick: () => this.handlePageRedirect(publish_path, "publish"),
      });
    }

    return buttons;
  }

  render() {
    const { host, back_url, page_path, publish_path, ...rest } = this.props;

    const { dirty, page } = this.state;

    return (
      <ErrorBoundary>
        <div className="widgets-spacings-reset">
          <MediaManagerContext.Provider value={mediaManager}>
            <PageProvider {...rest}>
              <GalleryLayout page={page} {...rest}>
                <PageBuilder
                  host={host}
                  dirty={dirty}
                  library={LIBRARY}
                  adminLibrary={ADMIN_LIBRARY}
                  spacings={SPACINGS}
                  themes={THEMES}
                  page={page}
                  onChange={(changedPage) => this.handleChange(changedPage)}
                  onSave={(pageToSave) => this.handleSave(pageToSave)}
                  onExit={() => (window.location.href = back_url)}
                  identifier="cg-website"
                  sidebarButtons={this.customButtons(page_path, publish_path)}
                />
              </GalleryLayout>
            </PageProvider>
          </MediaManagerContext.Provider>
        </div>
      </ErrorBoundary>
    );
  }
}

AdminBuilder.defaultProps = {
  host: "http://localhost:3000/",
};

export default AdminBuilder;
