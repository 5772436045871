import React from "react";
import {
  TextInput,
  SelectMenu,
  ListInput,
  RaketaUIProvider,
} from "@raketa-cms/raketa-cms";

const ExternalServiceItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      onChange={(value) => onChangeItem("name", value)}
      value={settings.name}
      pattern="[a-z]{1}[a-z\-_]*[a-z]{1}"
      hint={`API endpoint: /api/global-notice/${settings.name}`}
    />

    <SelectMenu
      label="Active"
      options={[
        ["1", "Yes"],
        ["0", "No"],
      ]}
      value={settings.active}
      onChange={(value) => onChangeItem("active", value)}
    />
  </div>
);

const GlobalNoticeExternalActivityEditor = ({ name, label, hint, value }) => {
  const [items, setItems] = React.useState(value || []);

  return (
    <RaketaUIProvider>
      <div className="field-pannel form-group">
        <ListInput
          label={label}
          listItem={(settings, onChangeItem) => (
            <ExternalServiceItem
              settings={settings}
              onChangeItem={onChangeItem}
            />
          )}
          primaryField="name"
          onChangeList={(_, newItems) => setItems(newItems)}
          items={items}
          template={{
            name: "",
            active: "0",
          }}
        />

        {hint && (
          <div
            className="help-block"
            dangerouslySetInnerHTML={{ __html: hint }}
          />
        )}

        <input type="hidden" name={name} value={JSON.stringify(items)} />
      </div>
    </RaketaUIProvider>
  );
};

export default GlobalNoticeExternalActivityEditor;
