import React from "react";

const NumberInput = ({ name, label, value, hidden }) => (
  <div>
    {!hidden && <label className="control-label">{label}</label>}

    <input
      className="form-control"
      type={hidden ? "hidden" : "number"}
      name={name}
      defaultValue={value}
    />
  </div>
);

const orderedCurrencies = [
  "EUR",
  "GBP",
  "USD",
  "KRW",
  "CNY",
  "JPY",
  "BRL",
  "RUB",
  "TRY",
];

const renderPrices = (prices, name, hidden) =>
  orderedCurrencies.map((currencyCode, idx) => {
    const price = prices.find((pr) => pr.code === currencyCode) || {
      code: currencyCode,
      value: 0,
    };

    return (
      <NumberInput
        key={idx}
        name={`${name}[${price.code}]`}
        value={price.value}
        label={price.code}
        hidden={hidden}
      />
    );
  });

const PricesInput = ({ prices, error, name, hidden = false }) => (
  <div className="price-item">
    <div className="price-row">
      <div className={`form-group ${error ? "has-error" : ""}`}>
        <div className="prices-input">{renderPrices(prices, name, hidden)}</div>

        {error && <span className="help-block wrapped">{error}</span>}
      </div>
    </div>
  </div>
);

export default PricesInput;
