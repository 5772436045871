import gql from 'graphql-tag';

const ProductEntry = gql`
  fragment ProductEntry on Product {
    id
    slug
    name
    store_link
  }
`;

export default ProductEntry;
