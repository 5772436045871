import React from 'react';
import Input from 'components/admin/Fields/Input';
import SelectInput from 'components/admin/Fields/SelectInput';

// Users is an array with format [name, id] required by the SelectInput
const defaultValue = (translator, users) => {
  if (translator.user_id === undefined) { return String((users.length > 0 && users[0][1]) || ''); }

  return String(translator.user_id || '');
}

const Translator = ({ prefix, translator, users, language, labels }) => (
  <div>
    <SelectInput
      name={`${prefix}[user_id]`}
      label={labels[language]}
      value={defaultValue(translator, users)}
      error={translator.user_error}
      collection={users}
    />

    <input
      type="hidden"
      name={`${prefix}[language]`}
      value={language}
    />
  </div>
)

export default Translator;
