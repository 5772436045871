import React from 'react';

const TextAreaInput = ({ label, name, error, value }) => (
  <div className={`form-group ${error ? 'has-error' : ''}`}>
    {label &&
      <label className="control-label">{label}</label>
    }
    <textarea
      className="form-control"
      name={name}
      defaultValue={value}
    />

    {error && <span className="help-block">{error}</span>}
  </div>
);

export default TextAreaInput;
