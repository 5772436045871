import React from 'react';
import ReactPlaceholder from 'react-placeholder';

const Placeholder = ({ realComponent, placeholderComponent, ready, data }) => {
  const PlaceholderComponent = placeholderComponent;
  const customPlaceholderData = data ? data : {};
  const RealComponent = realComponent;

  return (
    <div className="show-loading-animation">
      <ReactPlaceholder
        ready={!ready}
        customPlaceholder={<PlaceholderComponent {...customPlaceholderData} />}
        showLoadingAnimation
      >
        {(ready === false) && <RealComponent />}
      </ReactPlaceholder>
    </div>
  )
}

export default Placeholder;
