import React from "react";
import { TextInput, ListInput } from "@raketa-cms/raketa-cms";

const StringsEditor = ({ value, name, label }) => {
  const [items, setItems] = React.useState(
    (value || []).map((word, idx) => ({ id: idx, word: word }))
  );

  return (
    <div className="field-pannel form-group">
      <ListInput
        label={label}
        listItem={(settings, onChangeItem, idx) => (
          <TextInput
            label={`Word ${idx + 1}`}
            onChange={(value) => onChangeItem("word", value)}
            value={settings.word}
          />
        )}
        primaryField="word"
        onChangeList={(_, newItems) => setItems(newItems)}
        items={items}
        template={{ id: "", word: "" }}
      />

      <input
        type="hidden"
        name={name}
        value={JSON.stringify(items.map((item) => item.word))}
      />
    </div>
  );
};

export default StringsEditor;
