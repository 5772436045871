export default {
  padding: 0,
  margin: 0,
  boxSizing: 'border-box',
  fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontSize: '16px',
  lineHeight: '1.5',
  color: '#000',
  fontWeight: 'normal',
  backgroundColor: 'transparent',
  display: 'block',
  marginBottom: '1em',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '12px',
  cursor: 'pointer',
  color: '#000',
}