import React from "react";
import { LIBRARY } from "@chaos/website-frontend";
import PageProvider from "./PageProvider";
import GalleryLayout from "./GalleryLayout";

function breadcrumbsWidget(breadcrumbs) {
  return {
    widgetId: "breadcrumbs-auto",
    id: "breadcrumbs-auto",
    component: "BreadcrumbsWidget",
    settings: {
      list: breadcrumbs,
      variant: "edge",
    },
  };
}

function addItem(page, idx, item) {
  const result = [];

  page.widgets.forEach((w, wIdx) => {
    if (wIdx === idx) {
      result.push(item);
    }

    result.push(w);
  });

  return result;
}

function addBreadcrumbs(page, breadcrumbs) {
  if (!breadcrumbs || breadcrumbs?.length === 0) {
    return [...page.widgets];
  }

  if (page.breadcrumb_position === "top") {
    return addItem(page, 0, breadcrumbsWidget(breadcrumbs));
  }

  let navIdx = null;
  let heroIdx = null;

  for (let i = 0; i < page.widgets.length; i++) {
    const w = page.widgets[i];

    if (
      navIdx === null &&
      ["AdvancedSubMenuWidget", "SubMenuWidget"].includes(w["component"])
    ) {
      navIdx = i;
    }

    if (
      heroIdx === null &&
      [
        "FullWidthImageWidget",
        "FullWidthVideoWidget",
        "LeadImageSliderWidget",
      ].includes(w["component"])
    ) {
      heroIdx = i;
    }
  }

  if (page.breadcrumb_position === "following" && navIdx !== null) {
    return addItem(page, navIdx + 1, breadcrumbsWidget(breadcrumbs));
  }

  if (page.breadcrumb_position === "after-hero" && heroIdx !== null) {
    return addItem(page, heroIdx + 1, breadcrumbsWidget(breadcrumbs));
  }

  if (navIdx !== null) {
    return addItem(page, navIdx + 1, breadcrumbsWidget(breadcrumbs));
  }

  if (heroIdx !== null) {
    return addItem(page, heroIdx + 1, breadcrumbsWidget(breadcrumbs));
  }

  return addItem(page, 0, breadcrumbsWidget(breadcrumbs));
}

const RenderWidgets = ({ page, breadcrumbs }) => {
  return addBreadcrumbs(page, breadcrumbs).map((widget, idx) => {
    const WidgetComponent = LIBRARY[widget.component];

    if (!WidgetComponent) {
      console.error(
        `Component ${widget.component} does not exist in the library.`
      );
      return null;
    }

    return React.createElement(
      LIBRARY[widget.component],
      Object.assign({}, { key: idx }, widget.settings)
    );
  });
};

const PageRender = ({ page, breadcrumbs, ...rest }) => (
  <div>
    <PageProvider {...rest}>
      <GalleryLayout page={page} {...rest}>
        <RenderWidgets page={page} breadcrumbs={breadcrumbs} />
      </GalleryLayout>
    </PageProvider>
  </div>
);

export default PageRender;
