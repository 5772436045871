import jQuery from 'jquery';

var DocumentManager = {
  create: function (data, url, callback) {
    var formData = new FormData();
    formData.append('document[title]', data.title);
    formData.append('document[document_library_id]', data.library_id);
    formData.append('document[asset]', data.file);

    jQuery.ajax({
      url: url,
      data: formData,
      dataType: 'json',
      type: 'POST',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      processData: false,
      contentType: false,
      success: function (doc) {
        callback(doc);
      }
    });
  },
  update: function (data, url, callback) {
    jQuery.ajax({
      url: url,
      type: 'PATCH',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      data: {document: data},
      dataType: 'json',
      success: function (doc) {
        callback(doc);
      }
    });
  },
  destroy: function (url, callback) {
    jQuery.ajax({
      url: url,
      type: 'DELETE',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      dataType: 'json',
      success: function (doc) {
        callback(doc);
      }
    });
  },
  all: function (url, params, callback) {
    jQuery.ajax({
      url,
      type: 'GET',
      beforeSend: (xhr) => { xhr.setRequestHeader('X-CSRF-Token', jQuery('meta[name="csrf-token"]').attr('content')); },
      data: params,
      dataType: 'json',
      success: (images) => callback(images),
    })
  }
};

export default DocumentManager;
