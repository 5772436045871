import React from "react";
import ReactDatePicker from "react-datepicker";
import { formatSimpleDate } from "helpers/dateTime";

const DatePicker = ({ value, minDate, dates, onChange, label }) => {
  const dateProps = {};

  if (value !== "") dateProps.selected = new Date(value);
  if (minDate !== "") dateProps.minDate = new Date(minDate);
  if (typeof dates !== "undefined")
    dateProps.includeDates = dates.map((d) => new Date(d));

  return (
    <div className="form-group date">
      <label htmlFor className="form-label">
        {label}
      </label>

      <ReactDatePicker
        {...dateProps}
        locale="en"
        dateFormat="dd/MM/yyyy"
        onChange={(date) => onChange(formatSimpleDate(date))}
      />
    </div>
  );
};

export default DatePicker;
