import React from "react";
import parseMarkdown from "helpers/parseMarkdown";

const ButtonWithDescription = ({ settings, buttonClass }) => {
  const { id, label, link, target, follow_link, description } = settings;

  return (
    <span className="button-description-wrapper">
      <a
        href={link}
        className={buttonClass}
        id={id ? id : ""}
        target={target}
        rel={follow_link === "no-follow" ? "nofollow" : ""}
      >
        {label}
      </a>

      <br />

      <span className="button-description">
        {parseMarkdown(description, true)}
      </span>
    </span>
  );
};

const ButtonWithoutDescription = ({ settings, buttonClass }) => {
  const { id, label, link, target, follow_link } = settings;

  return (
    <a
      href={link}
      className={buttonClass}
      id={id ? id : ""}
      target={target}
      rel={follow_link === "no-follow" ? "nofollow" : ""}
    >
      {label}
    </a>
  );
};

const Button = ({ settings, className }) => {
  const { label, link, type, description } = settings;

  let buttonClass;

  if (type === "primary") buttonClass = "btn-active-dark";
  if (type === "secondary") buttonClass = "btn-passive-light";
  if (type === "text") buttonClass = "btn-text";
  if (className) buttonClass = `${buttonClass} ${className}`;

  return (
    <React.Fragment>
      {description && link && label && (
        <ButtonWithDescription settings={settings} buttonClass={buttonClass} />
      )}
      {!description && link && label && (
        <ButtonWithoutDescription
          settings={settings}
          buttonClass={buttonClass}
        />
      )}
    </React.Fragment>
  );
};

export default Button;
