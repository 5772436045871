import React from 'react';
import FieldsTextInput from 'components/shared/Fields/TextInput/TextInput';
import FieldsSelectMenu from 'components/admin/Fields/SelectMenu/SelectMenu';
import BaseReducer from 'reducers/BaseReducer';

class SelectField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this.props.data.options || [],
    };
  }

  newOptionFactory(value) {
    return { value };
  }

  handleAddOption() {
    const value = this.refs.newOption.getValue();
    const newOptions = BaseReducer.add(this.state.options, this.newOptionFactory(value));

    this.setState({ options: newOptions }, () => {
      this.refs.newOption.reset();
      this.props.onChange('options', this.state.options);
 },
    );
  }

  handleChangeOption(idx, value) {
    this.setState({ options: BaseReducer.updateField(this.state.options, idx, 'value', value) }, () => this.props.onChange('options', this.state.options));
  }

  handleDeleteOption(idx) {
    if (!confirm('Are you sure? ')) return;

    const newOptions = BaseReducer.remove(this.state.options, idx);
    this.setState({ options: [] }, () => {
      this.setState({ options: newOptions }, () => this.props.onChange('options', this.state.options));
    });
  }

  renderOptions() {
    if (!this.state.options.length) return (<p className="alert alert-info">There are no options yet.</p>);

    return this.state.options.map((option, idx) => (
      <div key={idx} className="option-wrapper">
        <FieldsTextInput onChange={e => this.handleChangeOption(idx, e.target.value)} value={option.value}  />
        <button type="button" className="btn btn-sm btn-link" onClick={() => this.handleDeleteOption(idx)}>Delete</button>
      </div>
    ));
  }

  render() {
    return (
      <div>
        <h4>Dropdown</h4>
        <div className="fields-wrapper">
          <FieldsTextInput label="Name" onChange={e => this.props.onChange('name', e.target.value)} value={this.props.data.name} />
          <FieldsTextInput label="Label" onChange={e => this.props.onChange('label', e.target.value)} value={this.props.data.label} />
          <FieldsTextInput label="Prompt" onChange={e => this.props.onChange('prompt', e.target.value)} value={this.props.data.prompt} />
        </div>
        <br />

        <FieldsSelectMenu
          label="Required?"
          onChange={e => this.props.onChange('required', e.target.value)}
          value={this.props.data.required}
          options={[['yes', 'Yes'], ['no', 'No']]}
        />

        <div className="add-new-option">
          <FieldsTextInput label="Options" ref="newOption" />
          <button type="button" className="btn btn-sm btn-success" onClick={e => this.handleAddOption()}>Add Option</button>
        </div>

        <div className="options-container">
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

export default SelectField;
