import React from 'react';

const Checkbox = ({ name, isChecked, onChange }) => (
  <label style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '0.5em' }}>
    <input
      type="checkbox"
      onChange={(e) => onChange(e.target.checked)}
      checked={isChecked}
      style={{ marginRight: '0.5em' }}
    />

    <span style={{ color: '#000' }}>{name}</span>
  </label>
);

export default Checkbox;
