import React, { useState } from 'react';
import MarkdownModal from 'components/admin/MarkdownModal';

export default ({ label, value, name, placeholder }) => {
  const [newValue, setNewVlaue] = useState(value);
  const [modalVisibility, setModalVisibility] = useState(false);

  return (
    <div className="textarea form-group">
      <label>{label}</label>

      <textarea
        className="form-control"
        onChange={e => setNewVlaue(e.target.value)}
        placeholder={placeholder && placeholder}
        value={newValue}
      />

      <span className="dialog-anchor glyphicon glyphicon-question-sign" onClick={() => setModalVisibility(true)}/>

      <MarkdownModal open={modalVisibility} onClose={() => setModalVisibility(false)} />

      <input type="hidden" name={name} value={newValue} />
    </div>
  )
}