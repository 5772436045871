import React from 'react';

export default ({ open, children }) => (
  <div style={{ display: open ? 'block' : 'none' }}>
    <div className="modal-backdrop in" />

    <div className="modal in" style={{ display: 'block' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  </div>
);
