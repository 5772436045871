import gql from "graphql-tag";

import ImageEntry from "../../fragments/ImageEntry";
import ProductEntry from "../../fragments/ProductEntry";

const GALLERY = gql`
  query($input: SlugInput!) {
    pagesGallery(input: $input) {
      navigation {
        previous {
          id
          slug
          name
        }
        next {
          id
          slug
          name
        }
      }
      related_galleries {
        id
        name
        description
        slug
        visible
        status
        url
        author {
          id
          name
          slug
        }
        industry {
          id
          name
          slug
        }
        image {
          ...ImageEntry
        }
      }
      gallery {
        id
        name
        description
        slug
        visible
        status
        url
        author {
          id
          name
          slug
        }
        industry {
          id
          name
          slug
        }
        image {
          ...ImageEntry
        }
        gallery_images {
          id
          alt
          video_url
          urls {
            original
            thumb
            gallery_masonry_horizontal
            gallery_masonry_horizontal_wide
            gallery_masonry_vertical
            gallery_image
          }
        }
        products {
          ...ProductEntry
        }
      }
    }
  }
  ${ImageEntry}
  ${ProductEntry}
`;

export default GALLERY;
