import React from 'react';
import { EmbeddedVideo } from '@raketa-cms/raketa-cms';
import Modal from 'components/ui/Modal';

const VideoModal = ({ isOpen, url, onClose }) => (
  <Modal
    className="modal-video-wrapper"
    open={isOpen}
    onClose={onClose}
  >
    <div className="video-modal">
      <div className="modal-video-inner">
        <div className="modal-video content-wrapper">
          <EmbeddedVideo videoUrl={url} autoplay={isOpen} />
        </div>
      </div>
    </div>
  </Modal>
);

export default VideoModal;
