import BaseReducer from './BaseReducer';

class LeadFormFieldsReducer extends BaseReducer {
  static changeField(idx, fields, field, newValue) {
    let newFields = [];

    fields.forEach((item, i) => {
      if (idx == i) item.data[field] = newValue;
      newFields.push(item);
    });

    return newFields;
  }

  static changeRequired(fields, idx, newRequired) {
    let newFields = [];

    fields.forEach((item, i) => {
      if ( idx == i ) item.data.required = newRequired;
      newFields.push(item);
    });

    return newFields;
  }
}

export default LeadFormFieldsReducer;
