import React from "react";
import Autocomplete from "react-autocomplete";
import jQuery from "jquery";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      q: props.q || "",
    };
  }

  handleProductSearch(q) {
    const { search_url } = this.props;

    this.setState({ q, articles: [] }, () => {
      if (q.length < 3) return;
      jQuery.get(
        search_url,
        { q },
        (articles) => this.setState({ articles }),
        "json"
      );
    });
  }

  handleGoToArticle(articleName) {
    const { articles, items } = this.state;

    const selectedArticle = articles.find((item) => item.title === articleName);

    if (typeof window !== "undefined")
      window.location.href = selectedArticle.url;
  }

  render() {
    const { search_url, placeholder } = this.props;
    const { q, articles } = this.state;

    const menuStyle = {
      boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
      background: "#fff",
      position: "absolute",
      top: "auto",
      overflow: "auto",
      left: "0",
      zIndex: "5",
    };

    return (
      <div className="faq-search">
        <form method="get" action={search_url}>
          <Autocomplete
            getItemValue={(item) => item.title}
            items={articles}
            renderMenu={(items, value, style) => (
              <div style={{ ...style, ...menuStyle }}>
                {items.slice(0, 6)}
                {items.length > 6 && (
                  <button type="submit" className="see-all">
                    See all results ({items.length})
                  </button>
                )}
              </div>
            )}
            renderItem={(item, isHighlighted) => (
              <div
                style={{
                  padding: "1em",
                  cursor: "pointer",
                  background: isHighlighted ? "lightgray" : "white",
                }}
                key={item.id}
              >
                {item.title}{" "}
                {item.faq_category && (
                  <small className="category-name">
                    {" "}
                    - {item.faq_category.name}
                  </small>
                )}
              </div>
            )}
            value={q}
            onChange={(e) => this.handleProductSearch(e.target.value)}
            onSelect={(articleName) => this.handleGoToArticle(articleName)}
            inputProps={{ placeholder, className: "faq-search-input" }}
            wrapperStyle={{ display: "inline-block", width: "100%" }}
            menuStyle={menuStyle}
          />

          <button type="submit" className="icon-search" />

          <input type="hidden" name="q" value={q} />
        </form>
      </div>
    );
  }
}

export default Search;
