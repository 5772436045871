import React from "react";
import Query from "graphql/Query";
import { GALLERY_PAGE } from "graphql/queries";
import { GalleryContent } from "components/app/pages/gallery";
import { hostUrl, updateHistory } from "./helpers";
import GalleryPlaceholder from "./GalleryPlaceholder";

const GalleryModal = ({ slug, currentUrl, onClose, onGalleryChange }) => (
  <div className="gallery-modal">
    <div
      className="overlay"
      onClick={() => {
        history.pushState(null, null, currentUrl);
        onClose();
      }}
    />
    <div className="content">
      <Query
        query={GALLERY_PAGE}
        variables={{
          input: { slug },
        }}
      >
        {({ error, loading, data }) => {
          if (error) return `Error! ${error.message}`;

          const { pagesGallery } = data;

          return (
            <GalleryPlaceholder
              ready={loading}
              realComponent={() => (
                <GalleryContent
                  gallery={pagesGallery.gallery}
                  navigation={pagesGallery.navigation}
                  relatedGalleries={pagesGallery.related_galleries}
                  hostUrl={hostUrl(currentUrl)}
                  onGalleryChange={(event, slug) => {
                    event.preventDefault();
                    updateHistory(currentUrl, slug);
                    onGalleryChange(slug);
                  }}
                  onClose={(event) => {
                    event.preventDefault();
                    history.pushState(null, null, currentUrl);
                    onClose();
                  }}
                />
              )}
            />
          );
        }}
      </Query>
    </div>
  </div>
);

export default GalleryModal;
