import React from 'react';

const renderOptions = (collection) => (
  collection.map(item => <option key={item[1]} value={item[1]}>{item[0]}</option>)
)

const SelectInput = ({ label, name, error, value, collection }) => (
  <div className={`form-group select ${error ? 'has-error' : ''}`}>
    {label &&
      <label className="control-label select">{label}</label>
    }
    <select name={name} defaultValue={value} className="form-control select">
      <option value=""></option>
      {renderOptions(collection)}
    </select>

    {error && <span className="help-block">{error}</span>}
  </div>
);

export default SelectInput;
