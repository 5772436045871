import React from "react";
import A from "components/app/A";

class GalleryItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.item.id,
      filename: this.props.item.image_file_name,
      url: this.props.item.url,
    };
  }

  destroy() {
    if (confirm("Are you sure? ")) this.props.onDestroy(this.state.id);
  }

  render() {
    return (
      <div className="item" key={this.state.id}>
        <span style={{ paddingRight: "1em" }}>{this.state.filename}</span>
        <A href="javascript:;" onClick={() => this.destroy()}>
          <i className="glyphicon glyphicon-remove"></i>
        </A>
      </div>
    );
  }
}

export default GalleryItem;
