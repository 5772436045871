const hostUrl = (url) => {
  const newUrl = new URL(url);

  return newUrl.origin;
};

const galleryPath = (currentUrl, gallerySlug) =>
  [
    hostUrl(currentUrl).replace(new RegExp("[/]$"), ""),
    "gallery",
    gallerySlug.replace(new RegExp("^[/]"), ""),
  ].join("/");

const updateHistory = (currentUrl, slug) =>
  history.pushState(null, null, galleryPath(currentUrl, slug));

export { hostUrl, galleryPath, updateHistory };
