import React from 'react';
import AdminModal from 'components/admin/Modal/Modal';
import AdminModalHeader from 'components/admin/Modal/ModalHeader';
import AdminModalBody from 'components/admin/Modal/ModalBody';
import AdminModalFooter from 'components/admin/Modal/ModalFooter';

export default ({ open, onClose }) => (
  <AdminModal open={open}>
    <AdminModalHeader title="Mardown Help" onClose={() => onClose()} />

    <AdminModalBody>
      <div className="row">
        <div className="col-md-5">
          <h5><strong>Text format</strong></h5>
          <p><strong>**bold**</strong> <br /> <em>*italic*</em></p>

          <h5><strong>Paragraph</strong></h5>
          <p>Text on separate newline</p>

          <h5><strong>Lists</strong></h5>
          <p>- List item <br /> - List item <br /> - List item</p>
        </div>
        <div className="col-md-7 col-md-offset-0">
          <h5><strong>Headings</strong></h5>
          <p># Heading 1 <br /> ## Heading 2 <br /> ### Heading 3 <br /> #### Heading 4</p>

          <h5><strong>Link</strong></h5>
          <p>[I'm an inline style link](https://www.google.com)</p>

          <h5><strong>Image</strong></h5>
          <p>![alt text](/path/to/image.png)</p>
        </div>
      </div>
    </AdminModalBody>

    <AdminModalFooter>
      <button type="button" className="btn btn-link" onClick={() => onClose()}>Close</button>
    </AdminModalFooter>
  </AdminModal>
);