import React from "react";
import Node from "./Node";

// TODO: Room for optimisation on the recurrsion?
function copyAndUpdate(node, updater) {
  const newNode = {
    id: node.id,
    value: node.value,
    title: node.title,
    children: node.children
      .map((n) => copyAndUpdate(n, updater))
      .filter(Boolean),
  };

  if (node.root) {
    newNode.root = true;
  }

  return updater(newNode);
}

function deleteNode(currentNode, id) {
  if (currentNode.id === id) {
    return null;
  }

  return currentNode;
}

function addNode(currentNode, id) {
  if (currentNode.id === id) {
    return {
      ...currentNode,
      children: [
        ...currentNode.children,
        {
          id: `${new Date().getTime()}-${(Math.random() * 1000000).toFixed(0)}`,
          value: {},
          title: "",
          children: [],
        },
      ],
    };
  }

  return currentNode;
}

function updateNode(currentNode, id, value, title) {
  if (currentNode.id === id) {
    return {
      ...currentNode,
      value: value,
      title: title,
    };
  }

  return currentNode;
}

function reducer(state, action) {
  switch (action.type) {
    case "deleteNode":
      return copyAndUpdate(state, (node) =>
        deleteNode(node, action.data.nodeId)
      );

    case "addNode":
      return copyAndUpdate(state, (node) =>
        addNode(node, action.data.parentId)
      );

    case "editNode":
      return copyAndUpdate(state, (node) =>
        updateNode(
          node,
          action.data.nodeId,
          action.data.value,
          action.data.title
        )
      );
  }

  throw Error("Unknown action: " + action.type);
}

function Tree({ value, name, pagesUrl }) {
  const [tree, dispatch] = React.useReducer(reducer, {
    id: "root",
    root: true,
    value: "homepage",
    children: value,
  });

  return (
    <>
      <div className="breadcrumbs">
        <Node node={tree} level={0} onUpdate={dispatch} pagesUrl={pagesUrl} />
      </div>

      <input type="hidden" name={name} value={JSON.stringify(tree.children)} />

      {/* <pre>{JSON.stringify(tree.children, null, 2)}</pre> */}
    </>
  );
}

export default Tree;
