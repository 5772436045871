/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import React from "react";
import { LocaleContext } from "@chaos/website-frontend";
import Img from "components/ui/Img";
import RelatedGalleries from "./RelatedGalleries";
import GalleryHeader from "./GalleryHeader";
import GalleryVideo from "./GalleryVideo";

const pagePath = (hostUrl, locale, pageSlug) => {
  return [
    hostUrl.replace(new RegExp("[/]$"), ""),
    locale === "en" ? "" : locale,
    pageSlug.replace(new RegExp("^[/]"), ""),
  ]
    .filter(Boolean)
    .join("/");
};

const GalleryContent = ({
  gallery,
  navigation,
  relatedGalleries,
  hostUrl,
  onGalleryChange,
  onClose,
}) => {
  const locale = React.useContext(LocaleContext);

  return (
    <React.Fragment>
      <article className="gallery-details">
        <GalleryHeader
          products={gallery.products}
          industry={gallery.industry}
          author={gallery.author}
          navigation={navigation}
          gallerySlug={gallery.slug}
          galleryName={gallery.name}
          pagePath={(slug) => pagePath(hostUrl, locale, slug)}
          onGalleryChange={onGalleryChange}
          onClose={onClose}
        />
        <div className="container">
          <section className="gallery-content">
            {gallery.description && (
              <div className="gallery-desc">
                <p>{gallery.description}</p>
              </div>
            )}

            <div
              className={`gallery-images ${
                gallery.description ? "spacing-top" : ""
              }`}
            >
              {gallery.gallery_images.map((image, idx) => (
                <React.Fragment key={idx}>
                  {image.video_url ? (
                    <GalleryVideo image={image} />
                  ) : (
                    <Img
                      src={image.urls.gallery_image}
                      variant="gallery_image"
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </section>
        </div>
      </article>

      <RelatedGalleries
        galleries={relatedGalleries}
        pagePath={(slug) => pagePath(hostUrl, locale, slug)}
        onGalleryChange={onGalleryChange}
      />
    </React.Fragment>
  );
};

export default GalleryContent;
