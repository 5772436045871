class BaseReducer {
  static add(items, newItem) {
    return [
      ...items,
      newItem
    ];
  }

  static remove(items, idx) {
    let newItems = [];

    items.forEach((item, i) => {
      if (idx != i) newItems.push(item);
    });

    return newItems;
  }

  static reorder(items, newOrder) {
    let newItems = [];
    newOrder.forEach((i) => newItems.push(items[i]));

    return newItems;
  }

  static updateField(items, idx, key, value) {
    return [
      ...items.slice(0, idx),
      Object.assign({}, items[idx], {[key]: value}),
      ...items.slice(idx + 1)
    ];
  }
}

export default BaseReducer;
