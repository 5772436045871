import React from "react";

const Input = ({ type, label, name, error, value, hint, readonly }) => (
  <div className={`form-group ${error ? "has-error" : ""}`}>
    {label && <label className="control-label">{label}</label>}
    <input
      className="form-control"
      type={type}
      name={name}
      defaultValue={value}
      readOnly={readonly ? readonly : false}
    />

    {hint && (
      <React.Fragment>
        <div
          className="help-block"
          dangerouslySetInnerHTML={{ __html: hint }}
        />
        <br />
      </React.Fragment>
    )}

    {error && <span className="help-block">{error}</span>}
  </div>
);

export default Input;
