import React from "react";
import { UpsellCampaign } from "@chaos/website-frontend";
import { GeoIpProvider, LocalizationUrlContext } from "@chaos/website-frontend";

const UpsellCampaignPreview = ({
  currency,
  country_groups,
  geo_ip_country,
  data,
  localization_url,
}) => {
  return (
    <LocalizationUrlContext.Provider value={localization_url}>
      <GeoIpProvider country={geo_ip_country} allGroups={country_groups}>
        <UpsellCampaign
          currency={currency}
          campaign={data}
          addToCart={(products) => {
            console.log("Adding to cart: ");
            console.dir(products, { depth: Infinity });
            alert(
              `Adding to cart:\n${JSON.stringify(
                products.map((uc) => {
                  return { id: uc.id, qty: uc.qty, name: uc.details.name };
                }),
                null,
                2
              )}`
            );
          }}
        />
      </GeoIpProvider>
    </LocalizationUrlContext.Provider>
  );
};

export default UpsellCampaignPreview;
