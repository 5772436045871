import React from 'react';
import Translator from './Translator';

const findTranslator = (language, translators) => {
  return translators.find(item => item.language === language) || {}
};

const Translators = ({ namePrefix, allTranslators, translators, labels }) => (
  <div>
    {allTranslators.map(({language, users}, idx) => (
      <Translator
        key={idx}
        users={users}
        prefix={`${namePrefix}[]`}
        language={language}
        translator={findTranslator(language, translators)}
        labels={labels}
      />
    ))}
  </div>
)

export default Translators;
