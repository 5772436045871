import React from 'react';
import A from 'components/app/A';

class FileItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.item.id,
      title: this.props.item.title,
      url: this.props.item.url
    };

    this.handleUpdateTitle = this.handleUpdateTitle.bind(this);
    this.destroy           = this.destroy.bind(this);
    this.blur              = this.blur.bind(this);
  }

  handleUpdateTitle() {
    let title = this.refs.title.value;
    this.setState({ title: title });
  }

  blur() {
    let title = this.refs.title.value.trim();
    this.setState({ title: title });

    this.props.onBlurTitle(this.state);
  }

  destroy() {
    if (confirm('Are you sure? ')) this.props.onDestroy(this.state.id);
  }

  render() {
    return (
      <div className="item" key={ this.state.id }>
        <label>Title</label>
        <input ref="title" type="text" className="form-control" value={ this.state.title } onChange={ this.handleUpdateTitle } onBlur={ this.blur } />
        <A href="javascript:;" onClick={ this.destroy }>
            <i className="glyphicon glyphicon-remove"></i>
        </A>
      </div>
    );
  }
}


export default FileItem;
