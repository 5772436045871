import gql from 'graphql-tag';

const ImageEntry = gql`
  fragment ImageEntry on Image {
    image_library {
      id
      name
    }
    id
    name
    credits
    width
    height
    url
    thumb_url
    urls {
      original
      thumb
      gallery_masonry_horizontal
      gallery_masonry_horizontal_wide
      gallery_masonry_vertical
      simple_image_slider
      articles_2_columns
      full_width_image
      magazine_spotlight
      single_image_horizontal
      full_width_original
      mobile_square
    }
  }
`;

export default ImageEntry;
