import React from "react";
import { LIBRARY } from "@chaos/website-frontend";
import PageProvider from "../../cms/PageProvider";

const SubscriptionWidget = ({ settings, context }) => (
  <PageProvider {...context}>
    <LIBRARY.SubscriptionWidget {...settings} />
  </PageProvider>
);

export default SubscriptionWidget;
