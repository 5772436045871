import React from "react";
import { GraphQLProvider,
  AdminUserProvider,
  GeoIpProvider,
  PageUrlProvider,
  CurrencyProvider,
  FormsProvider,
  CaptchaProvider,
  CountriesProvider,
  LocaleProvider
} from "@chaos/website-frontend";

const PageProvider = ({
  gql_url,
  geo_ip_country,
  country_groups,
  currency,
  is_admin,
  current_url,
  forms_context,
  captcha_context,
  all_countries,
  locale,
  children,
}) => (
  <div>
    <AdminUserProvider isAdmin={is_admin}>
      <LocaleProvider locale={locale}>
        <GeoIpProvider country={geo_ip_country} allGroups={country_groups}>
          <PageUrlProvider url={current_url}>
            <GraphQLProvider gqlHost={gql_url}>
              <CurrencyProvider currency={currency}>
                <FormsProvider formsContext={forms_context}>
                  <CaptchaProvider captchaContext={captcha_context}>
                    <CountriesProvider allCountries={all_countries}>
                      {children}
                    </CountriesProvider>
                  </CaptchaProvider>
                </FormsProvider>
              </CurrencyProvider>
            </GraphQLProvider>
          </PageUrlProvider>
        </GeoIpProvider>
      </LocaleProvider>
    </AdminUserProvider>
  </div>
);

export default PageProvider;
