import React from "react";
import MarkdownIt from "markdown-it";

const parser = new MarkdownIt({
  html: true,
  breaks: true,
});

const parseMarkdown = (string, inline = false) => {
  if (!string) return;

  if (inline)
    return (
      <div dangerouslySetInnerHTML={{ __html: parser.renderInline(string) }} />
    );
  return <div dangerouslySetInnerHTML={{ __html: parser.render(string) }} />;
};

export default parseMarkdown;
