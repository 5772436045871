import React from "react";
import { ButtonSettings, SelectMenu, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker, imagePlaceholder } from "@raketa-cms/raketa-image-picker";
import { AdminRTE } from "@chaos/website-frontend";
import HubSpotListInput from "./HubSpotListInput";

const PopupInput = ({ value, onChange, options }) => (
  <>
    <SelectMenu
      label="Active"
      options={[
        ["yes", "Yes"],
        ["no", "No"],
      ]}
      value={value.active}
      onChange={(active) => onChange({ ...value, active })}
    />

    <TextInput
      label="ID"
      value={value.id}
      onChange={(id) => onChange({ ...value, id })}
      hint="This must be unique among the list of popups. There is no automatic validation for this!"
    />

    <HubSpotListInput
      label="HubSpot List"
      value={value.hubspot_list}
      options={options}
      onChange={(hubspot_list) => onChange({ ...value, hubspot_list })}
    />

    <ImagePicker
      label="Image"
      value={value.image}
      onChange={(image) => onChange({ ...value, image })}
    />

    <ImagePicker
      label="Icon"
      value={value.icon}
      onChange={(icon) => onChange({ ...value, icon })}
    />

    <TextInput
      label="Title"
      value={value.title}
      onChange={(title) => onChange({ ...value, title })}
    />

    <AdminRTE
      label="Description"
      value={value.description}
      onChange={(description) => onChange({ ...value, description })}
    />

    <ButtonSettings
      label="Button"
      value={value.button}
      onChange={(button) => onChange({ ...value, button })}
    />

    <ButtonSettings
      label="Mobile close button"
      value={value.mobile_close_button}
      onChange={(mobile_close_button) =>
        onChange({ ...value, mobile_close_button })
      }
    />
  </>
);

PopupInput.defaults = {
  active: "yes",
  hubspot_list: HubSpotListInput.defaults,
  image: imagePlaceholder("400x225"),
  icon: imagePlaceholder("48"),
  title: "Title",
  description:
    "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perspiciatis eos iure fugiat facilis officia praesentium totam molestias. Vitae aperiam sunt mollitia sit labore ut provident dicta, doloribus repellat. Excepturi, id?",
  button: ButtonSettings.defaults,
};

export default PopupInput;
