import React from "react";
import Input from "components/admin/Fields/Input";
import PricesInput from "./PricesInput";

const selectPromoPrices = (item) =>
  item.promo_prices.length === 0 ? item.prices : item.promo_prices;

const Item = ({ prefix, item, index, onDelete, labels }) => (
  <div className="pricelist-item">
    <div className="price-row">
      <Input
        type="number"
        name={`${prefix}[${index}][min_quantity]`}
        value={index === 0 ? 1 : item.min_quantity}
        error={item.min_quantity_error}
        label={labels.min_quantity}
        readonly={index === 0}
      />

      <PricesInput
        prices={item.prices}
        error={item.prices_error}
        itemIdx={index}
        prefix={prefix}
        name="prices"
      />

      <input
        type="hidden"
        name={`${prefix}[${index}][pricing_type]`}
        value={item.pricing_type}
      />

      <input
        type="hidden"
        name={`${prefix}[${index}][promo_active]`}
        value={item.promo_active}
      />

      <input
        type="hidden"
        name={`${prefix}[${index}][override_currency]`}
        value={item.override_currency}
      />

      <button
        type="button"
        className="btn btn-link btn-sm btn-danger"
        onClick={() => {
          onDelete(index);
        }}
      >
        {labels.remove_item_button}
      </button>
    </div>

    {item.promo_active && (
      <div className="price-row">
        <div className="filler"></div>
        <PricesInput
          prices={selectPromoPrices(item)}
          error={item.promo_prices_error}
          itemIdx={index}
          prefix={prefix}
          name="promo_prices"
        />
      </div>
    )}

    {!item.promo_active && (
      <PricesInput
        prices={selectPromoPrices(item)}
        error={item.promo_prices_error}
        itemIdx={index}
        prefix={prefix}
        name="promo_prices"
        hidden={true}
      />
    )}
  </div>
);

export default Item;
