import React from 'react';

const ProgressBar = ({ progress, progressMessage }) => (
  <div className="progress">
    <div className="progress-bar progress-bar-success" style={ {width: `${progress}%`} }>
      {progressMessage}
    </div>
  </div>
);

export default ProgressBar;
