import React from 'react';

class SelectMenu extends React.Component {
  getSelectedOption() {
    return this.refs.selected_option.value;
  }

  renderSelect() {
    let nodes = this.props.options.map((item, idx) =>
      (<option key={idx} value={item[0]}>{item[1]}</option>)
    );

    return (
      <select ref="selected_option" defaultValue={this.props.value} disabled={this.props.disabled} onChange={this.props.onChange} >
        {nodes}
      </select>
    );
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label> <br />

        {this.renderSelect()}
      </div>
    );
  }
}

export default SelectMenu;
