import React from "react";
import { ListInput, LinkSettings } from "@raketa-cms/raketa-cms";

const template = () => ({
  id: 1,
  link: { id: "", link: "", label: "", className: "", target: "", rel: "" },
});

const MenuItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

const MenuEditor = ({ value, name, label }) => {
  const [items, setItems] = React.useState(value.length > 0 ? value : []);

  return (
    <div className="field-pannel form-group">
      <label className="control-label">{label}</label>
      <ListInput
        listItem={(settings, onChangeItem) => (
          <MenuItem settings={settings} onChangeItem={onChangeItem} />
        )}
        primaryField="link.label"
        onChangeList={(_, newItems) => setItems(newItems)}
        items={items}
        template={template()}
      />

      <input type="hidden" name={name} value={JSON.stringify(items)} />
    </div>
  );
};

MenuEditor.defaultProps = {
  label: "Menu items",
};

export default MenuEditor;
