import React from "react";
import SortableList from "react-sortablejs";
import LeadFormSelectField from "./SelectField";
import LeadFormTextAreaField from "./TextAreaField";
import LeadFormTextField from "./TextField";
import LeadFormTitleField from "./TitleField";
import LeadFormHiddenField from "./HiddenField";
import LeadFormDateField from "./DateField";
import LeadFormFileField from "./FileField";
import LeadFormFieldsReducer from "reducers/LeadFormFieldsReducer";

class LeadFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fields:
        this.props.fields && this.props.fields.length > 0
          ? this.props.fields
          : [],
    };

    this.handleReorder = this.handleReorder.bind(this);
  }

  fieldFactory(type) {
    return {
      type,
      data: { name: "", label: "", placeholder: "", required: "yes" },
    };
  }

  getFields() {
    return {
      LeadFormSelectField,
      LeadFormTextAreaField,
      LeadFormTextField,
      LeadFormTitleField,
      LeadFormHiddenField,
      LeadFormDateField,
      LeadFormFileField,
    };
  }

  handleAddField(type) {
    const fields = this.state.fields;
    this.setState({
      fields: [...fields, this.fieldFactory(type)],
    });
  }

  handleChangeField(idx, field, value) {
    let newFields = LeadFormFieldsReducer.changeField(
      idx,
      this.state.fields,
      field,
      value
    );
    this.setState({ fields: newFields });
  }

  handleDeleteField(idx) {
    if (!confirm("Are you sure? ")) return;

    let newFields = LeadFormFieldsReducer.remove(this.state.fields, idx);
    this.setState({ fields: [] }, () => {
      this.setState({ fields: newFields });
    });
  }

  handleChangeRequired(e, idx) {
    let newRequired = e.target.value;
    let newFields = LeadFormFieldsReducer.changeRequired(
      this.state.fields,
      idx,
      newRequired
    );

    this.setState({ fields: newFields });
  }

  handleReorder(newOrder) {
    let newFields = LeadFormFieldsReducer.reorder(this.state.fields, newOrder);

    this.setState({ fields: [] }, () => {
      this.setState({ fields: newFields });
    });
  }

  renderFields() {
    if (this.state.fields.length == 0) return <p>No form fields, yet. </p>;

    const FIELDS = this.getFields();

    return this.state.fields.map((field, idx) => (
      <div key={idx} data-id={idx} className="lead-form-field">
        <div className="item-toolbar">
          <i className="glyphicon glyphicon-move"></i>
          <i
            className="glyphicon glyphicon-remove"
            onClick={() => this.handleDeleteField(idx)}
          ></i>
        </div>

        {React.createElement(FIELDS[field.type], {
          key: idx,
          data: field.data,
          onChange: (field, value) => this.handleChangeField(idx, field, value),
        })}
      </div>
    ));
  }

  render() {
    return (
      <div className="lead-form-fields">
        <h4>Form Fields</h4>

        <p>
          <strong>Hint:</strong> You can use <code>first_name</code>,{" "}
          <code>last_name</code>, <code>full_name</code>, <code>email</code> and{" "}
          <code>company</code> as names for the fields, to pre-populate this
          data from the logged user's profile
        </p>

        <div className="btn-group">
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormTextField")}
          >
            Add Text
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormTextAreaField")}
          >
            Add Textarea
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormSelectField")}
          >
            Add Dropdown
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormTitleField")}
          >
            Add Title
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormHiddenField")}
          >
            Add Hidden
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormDateField")}
          >
            Add Date
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormFileField")}
          >
            Add File
          </button>
        </div>

        <br />
        <br />

        <SortableList
          tag="div"
          className="lead-form-fields-wrapper"
          onChange={this.handleReorder}
          options={{ handle: ".glyphicon-move" }}
        >
          {this.renderFields()}
        </SortableList>

        <div className="btn-group">
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormTextField")}
          >
            Add Text
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormTextAreaField")}
          >
            Add Textarea
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormSelectField")}
          >
            Add Dropdown
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormTitleField")}
          >
            Add Title
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormHiddenField")}
          >
            Add Hidden
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormDateField")}
          >
            Add Date
          </button>
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={() => this.handleAddField("LeadFormFileField")}
          >
            Add File
          </button>
        </div>

        <input
          type="hidden"
          name={this.props.field_name}
          value={JSON.stringify(this.state.fields)}
        />
      </div>
    );
  }
}

export default LeadFields;
