import React from 'react';
import jQuery from 'jquery';
import FieldsRelationBox from './RelationBox/RelationBox';

class LibrarySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = { options: this.props.options, selected: this.props.selected }

    this.handleSaveOption   = this.handleSaveOption.bind(this);
    this.handleChangeOption = this.handleChangeOption.bind(this);
    this.handleCancel       = this.handleCancel.bind(this);
  }

  notifyChanges(optionValue) {
    if (this.props.onChange) this.props.onChange(optionValue);
  }

  handleChangeOption(optionValue) {
    this.notifyChanges(optionValue);
    this.setState({ selected: optionValue });
  }

  handleSaveOption(optionName) {
    let data = {};
    data[this.props.model_name] = { name: optionName, slug: toSlug(optionName) };

    let self = this;
    jQuery.post(this.props.url, data, function (newOption) {
      let newOptions = [...self.state.options, newOption];
      self.setState({ options: newOptions, selected: newOption.id }, () => self.notifyChanges(newOption.id));
    }, 'json');
  }

  handleCancel() {
    this.setState({ selected: this.state.options[0] });
  }

  render() {
    return (
      <FieldsRelationBox
        name={ this.props.name }
        id={ this.props.id }
        options={ this.state.options }
        selected={ this.state.selected }
        onCreate={ this.handleSaveOption }
        onChange={ this.handleChangeOption }
        onCancel={ this.handleCancel }
      />
    );
  }
}

export default LibrarySelect;
