import React from 'react';
import FieldsTextInput from 'components/shared/Fields/TextInput/TextInput';
import FieldsSelectMenu from 'components/admin/Fields/SelectMenu/SelectMenu';

const HiddenField = ({ data, onChange, onDelete }) => (
  <div>
    <h4>Hidden input</h4>
    <div className="fields-wrapper">
      <FieldsTextInput label="Name" onChange={ (e) => onChange('name', e.target.value) } value={ data.name } />
    </div>
  </div>
);

export default HiddenField;
