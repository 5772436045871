import React from "react";
import { ListInput, SelectMenu } from "@raketa-cms/raketa-cms";

const template = () => ({ id: 1, icon: "" });
const selectOptions = () => [
  ["", ""],
  ["facebook", "Facebook"],
  ["twitter", "Twitter"],
  ["linkedin", "LinkedIn"],
  ["youtube", "YouTube"],
  ["instagram", "Instagram"],
  ["blogger", "Blogger"],
  ["weibo", "Weibo"],
  ["youku", "Youku"],
];

const IconItem = ({ settings, onChangeItem }) => (
  <div>
    <SelectMenu
      label=""
      options={selectOptions()}
      placeholder=""
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />
  </div>
);

const SocialIconsEditor = ({ value, label, name }) => {
  const [items, setItems] = React.useState(value.length > 0 ? value : []);

  return (
    <div className="field-pannel form-group">
      <label className="control-label">{label}</label>
      <ListInput
        listItem={(settings, onChangeItem) => (
          <IconItem settings={settings} onChangeItem={onChangeItem} />
        )}
        primaryField="icon"
        onChangeList={(_, newItems) => setItems(newItems)}
        items={items}
        template={template()}
      />

      <input type="hidden" name={name} value={JSON.stringify(items)} />
    </div>
  );
};

SocialIconsEditor.defaultProps = {
  label: "Icons",
};

export default SocialIconsEditor;
