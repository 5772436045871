import React from "react";

const findValue = (options, label) => {
  if (!label) return;

  const idx = options.findIndex((o) => label === o.label);

  return idx !== -1 ? options[idx].value : null;
};

const findLabel = (options, value) => {
  if (!value) return;

  const idx = options.findIndex((o) => value === o.value);

  return idx !== -1 ? options[idx].label : null;
};

const AutoComplete = ({ label, options, error, onChange, ...rest }) => {
  const randomListId = Math.floor(Math.random() * 1000);

  return (
    <div className={`form-group ${error ? "has-error" : ""}`}>
      <label className="control-label">{label}</label>

      <input
        type="text"
        className="form-control"
        autoComplete="off"
        list={`autocomplete-list-${randomListId}`}
        onChange={onChange}
        {...rest}
      />

      <datalist id={`autocomplete-list-${randomListId}`}>
        {options.map((item) => (
          <option key={item} value={item} />
        ))}
      </datalist>

      {error && <span className="help-block">{error}</span>}
    </div>
  );
};

const AutoCompleteSelect = ({
  label,
  options,
  errorMessage,
  value,
  onChange,
  ...rest
}) => {
  const labels = options.map((o) => o.label);

  return (
    <AutoComplete
      label={label}
      options={labels}
      value={findLabel(options, value)}
      error={errorMessage}
      onChange={(e) => {
        onChange(findValue(options, e.target.value));
      }}
      {...rest}
    />
  );
};

export default AutoCompleteSelect;
