import React from "react";
import { ListInput } from "@raketa-cms/raketa-cms";
import SelectInput from "components/admin/Fields/SelectInput";
import Input from "components/admin/Fields/Input";
import AutoCompleteSelect from "components/admin/Fields/AutocompleteSelect";

const CardsAutocomplete = ({ value, label, error, name, options }) => {
  const [cardId, setCardId] = React.useState(value);

  return (
    <React.Fragment>
      <AutoCompleteSelect
        label={label}
        options={options}
        placeholder="Start typing..."
        errorMessage={error}
        value={cardId}
        required
        onChange={(newValue) => setCardId(newValue || "")}
      />

      <input type="hidden" name={name} defaultValue={cardId} />
    </React.Fragment>
  );
};

const TabCard = ({ cardSelectOptions, tabCard, idx, namePrefix }) => (
  <div>
    <CardsAutocomplete
      name={`${namePrefix}[package_pricing_tab_cards][${idx}][package_pricing_card_id]`}
      label="Card"
      value={tabCard.card_id}
      error={tabCard.card_error}
      options={cardSelectOptions}
    />

    <Input
      type="text"
      name={`${namePrefix}[package_pricing_tab_cards][${idx}][ribbon_text]`}
      label="Ribbon text"
      value={tabCard.ribbon_text}
      error={tabCard.ribbon_error}
    />

    <input
      type="hidden"
      name={`${namePrefix}[package_pricing_tab_cards][${idx}][ord]`}
      value={idx}
    />
  </div>
);

class Cards extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.tabCards && this.props.tabCards.length > 0) {
      this.state = {
        tabCards: this.props.tabCards.map((item, idx) =>
          Object.assign({ id: idx, ...item })
        ),
      };
    } else {
      this.state = { tabCards: [{ id: 1, card_id: undefined }] };
    }
  }

  render() {
    const { namePrefix, cardSelectOptions, tabCardTemplate } = this.props;
    const { tabCards } = this.state;

    return (
      <div className="package-pricing-cards-field">
        <ListInput
          itemsAlwaysOpen
          listItem={(tabCard, _onChangeItem, idx) => (
            <TabCard
              cardSelectOptions={cardSelectOptions}
              tabCard={tabCard}
              idx={idx}
              namePrefix={namePrefix}
            />
          )}
          template={tabCardTemplate}
          onChangeList={(_, tabCards) => this.setState({ tabCards: tabCards })}
          items={tabCards}
        />
      </div>
    );
  }
}

export default Cards;
