import React from "react";
import { TextInput, List } from "@raketa-cms/raketa-cms";
import labelStyles from "./labelStyles";

const SelectOptionField = ({ settings, onChangeItem }) => (
  <div className="fileds-row">
    <div className="options-filed-wrapper">
      <TextInput
        label="Value"
        onChange={(value) => onChangeItem("value", value)}
        value={settings.value}
      />
    </div>

    <div className="options-filed-wrapper">
      <TextInput
        label="Label"
        onChange={(value) => onChangeItem("label", value)}
        value={settings.label}
        hint="Supports markdown"
      />
    </div>
  </div>
);

export default ({ onChange, options }) => (
  <div className="options-wrapper">
    <div className="with-hint">
      <TextInput
        label="Items Separator"
        onChange={(itemsSeparator) =>
          onChange(Object.assign({}, options, { itemsSeparator }))
        }
        value={options.itemsSeparator}
      />

      <div className="hint">
        Defaults to ";". Example: "Apple;Orange;Bannana"
      </div>
    </div>

    <label htmlFor="" style={labelStyles}>
      Select options
    </label>
    <List
      listItem={(settings, onChangeItem) => (
        <SelectOptionField settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={(_, selectOptions) =>
        onChange(Object.assign({}, options, { selectOptions }))
      }
      items={options.selectOptions}
      primaryField="label"
      template={{ type: "value", name: "label" }}
    />
  </div>
);
