import React from 'react';
import A from 'components/app/A';
import Dropbox from './Dropbox';
import FieldsItemList from './ItemList';
import FieldsLibrarySelect from 'components/admin/Fields/LibrarySelect';
import LegacyProgressBar from 'components/Legacy/ProgressBar';
import GalleryItem from './GalleryItem';
import FileItem from './FileItem';
import DocumentManager from 'managers/document-manager';
import ImageManager from 'managers/image-manager';
import GalleryImageManager from 'managers/gallery-image-manager';

const MANAGERS = {
  DocumentManager,
  ImageManager,
  GalleryImageManager,
};

const ITEMS = {
  GalleryItem,
  FileItem,
};

class LibraryUploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      library_id: this.props.library.selected,
      items: [],
      uploadsCount: 0,
      uploadsInProgress: 0,
      skippedFiles: [],
    };

    this.handleBlurTitle     = this.handleBlurTitle.bind(this);
    this.handleDestroyItem   = this.handleDestroyItem.bind(this);
    this.handleChangeLibrary = this.handleChangeLibrary.bind(this);
  }

  getDataManager() {
    return MANAGERS[this.props.data_manager];
  }

  onDrop(files) {
    let component = this;
    let list_url = this.props.list_url;
    let upload_url = component.props.upload_url;
    let library_id = this.state.library_id;
    let data_manager = this.getDataManager();

    this.setState({ uploadsInProgress: files.length, uploadsCount: files.length }, () => {
      files.map((file) => {
        data_manager.all(list_url, {f: {title: file.name}}, (remoteFile) => {
          let uploadImage = true;

          if (remoteFile.length > 0) {
            if (!confirm(`Image with filename ${remoteFile[0].title} already exists in the ${remoteFile[0].library_name} library. \n\nRewrite?`)) uploadImage = false;
          }

          if (uploadImage) {
            data_manager.create({ title: file.name, library_id: library_id, file: file }, upload_url, (item) => {
              let items = component.state.items;
              items.push(item);
              component.setState({ items: items, uploadsInProgress: this.state.uploadsInProgress - 1 });
            });
          } else {
            const newSkippedFiles = [
              ...this.state.skippedFiles,
              {filename: file.name, library: remoteFile[0].library_name},
            ];

            this.setState({
              uploadsCount: this.state.uploadsCount - 1,
              uploadsInProgress: this.state.uploadsInProgress - 1,
              skippedFiles: newSkippedFiles,
            });
          }
        });

      });
    });
  }

  handleBlurTitle(item) {
    this.getDataManager().update({ title: item.title }, item.url, (resp) => {
      // successful update
    });
  }

  handleDestroyItem(item_id) {
    let items = this.state.items;
    let newItems = items.filter((item) => item.id != item_id);

    this.setState({ items: newItems });

    let item_to_delete = items.filter((item) => item.id == item_id )[0];

    let item_url = item_to_delete.url;
    this.getDataManager().destroy(item_url, (item) => {
      // successful destroy
    });
  }

  handleChangeLibrary(library_id) {
    this.setState({ library_id: library_id });

    let items = this.state.items;
    if (items.length > 0) {
      let library_key = this.props.library.model_name + '_id';
      let data_manager = this.getDataManager();

      items.forEach((item) => {
        let data = {};
        data[library_key] = library_id;

        data_manager.update(data, item.url, (item) => {
          // successful update
        });
      });
    }
  }

  renderProgress() {
    if (this.state.uploadsCount == 0) return;

    const progress = 100 - ((100 / this.state.uploadsCount) * this.state.uploadsInProgress);
    const uploaded = this.state.uploadsCount - this.state.uploadsInProgress
    const progressMessage = (uploaded == this.state.uploadsCount) ? 'Completed' : `${uploaded}/${this.state.uploadsCount}`

    return (<div>
      <LegacyProgressBar progress={progress} progressMessage={progressMessage} />
    </div>);
  }

  renderSkippedFiles() {
    if (this.state.skippedFiles.length == 0) return;

    return (
      <div>
        <h5>Skipped Files</h5>
        <ol>
          {this.state.skippedFiles.map((item, idx) => <li key={idx}><strong>{item.filename}</strong> already exists in <strong>{item.library}</strong></li>)}
        </ol>

        <br />
      </div>
    );
  }

  getLibraryUrl() {
    if (this.props.item_row == 'GalleryItem') {
      return this.props.library_base_path + this.state.library_id + '/gallery_images';
    } else {
      return this.props.library_base_path + this.state.library_id;
    }
  }

  render() {
    return (
        <div className="library-uploader">
          <Dropbox onDrop={ (files) => this.onDrop(files) } buttonLabel={ this.props.upload_button } />

          {this.renderProgress()}
          {this.renderSkippedFiles()}

          <div className={ this.state.items.length > 0 ? '' : 'hide' }>
            <h4>{ this.props.list_title }</h4>
            <FieldsItemList
              items={ this.state.items }
              row={ ITEMS[this.props.item_row] }
              onBlurTitle={ this.handleBlurTitle }
              onDestroyItem={ this.handleDestroyItem } />
            <br/>

            <label>Select Library</label>
            <FieldsLibrarySelect
              options={ this.props.library.options }
              selected={ this.state.library_id }
              name={ this.props.library.name }
              url={ this.props.library.url }
              model_name={ this.props.library.model_name }
              onChange={ this.handleChangeLibrary }
              id='library'
            />
            <br/>

            <A href={ this.getLibraryUrl() } className="btn btn-lg btn-success">Go to library</A>
          </div>
        </div>
    );
  }
};

export default LibraryUploader;
