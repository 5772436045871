import React from 'react';
import parseMarkdown from 'helpers/parseMarkdown';

const Tooltip = ({ tooltipText }) => (
  <span className="tooltip-wrapper">
    <span className="tooltip-triger" />

    <span className="tooltip">
      <span className="tooltip-text">
        {parseMarkdown(tooltipText)}
      </span>
    </span>
  </span>
);

export default Tooltip;
