import React from "react";
import { Header } from "@chaos/layout";
import Cookie from "./../../../Cookie";

const TEN_YEARS = 10 * 365;
const WEBSHOP_ENV_MAPPING = {
  development: "dev",
  staging: "staging",
  production: "production",
};

const WebshopButton = ({ language, env }) => {
  const [webshop, setWebshop] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;

    if (!webshop) {
      import("webshop").then((mod) => {
        if (mounted) {
          setWebshop(mod);
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (!webshop) {
    return <span />;
  }

  return <webshop.WebshopButton language={language} theme="chaos" env={env} />;
};

const addToCart = async (a, b, c) => {
  const mod = await import("webshop");

  mod.addToCart(a, b, c);
};

const SiteHeader = ({ cookieName, cookieDomain, header, language, env }) => (
  <Header
    onChangeLanguage={(language, url) => {
      Cookie.setCookie(
        cookieName,
        language.toLowerCase(),
        TEN_YEARS,
        cookieDomain
      );

      window.location.href = url;
    }}
    cart={{
      component: () => (
        <WebshopButton language={language} env={WEBSHOP_ENV_MAPPING[env]} />
      ),
      add: addToCart,
    }}
    {...header}
  />
);

export default SiteHeader;
