import React from 'react';

class ItemList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { items: this.props.items };

    this.destroyItem = this.destroyItem.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({ items: newProps.items });
  }

  destroyItem(item_id) {
    this.props.onDestroyItem(item_id);
  }

  render() {
    let self = this;
    let itemNodes = this.state.items.map((item) =>
      (<self.props.row item={ item } key={ item.id } onBlurTitle={ self.props.onBlurTitle } onDestroy={ self.destroyItem } />)
    );

    return (
        <div className="item-list">
          { itemNodes.length ? itemNodes : 'No items to add to this library. ' }
        </div>
    );
  }
}

export default ItemList;
