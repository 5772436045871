import React from "react";
import { FormGroup, Label } from "@raketa-cms/raketa-cms";
import Autocomplete from "./Autocomplete";

const HubSpotListInput = ({ label, value, onChange, options }) => (
  <FormGroup>
    {label && <Label>{label}</Label>}
    <Autocomplete
      value={value.list_id}
      options={options}
      onChange={(list_id) => onChange({ ...value, list_id })}
    />
  </FormGroup>
);

HubSpotListInput.defaults = {
  list_id: { id: 0, name: "Please select a list..." },
};

export default HubSpotListInput;
