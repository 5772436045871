import moment from "moment-timezone";

const formatSimpleDate = (date) =>
  [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, "0"),
    date.getDate().toString().padStart(2, "0"),
  ].join("-");

const formatDate = (dateString, format = false) => {
  if (format) {
    return moment(new Date(dateString)).utc().format(format);
  } else {
    return moment(new Date(dateString)).utc().format();
  }
};

const formatDateTime = (dateTimeString, timezone, format = false) => {
  if (format) {
    return moment(new Date(dateTimeString)).tz(timezone).format(format);
  } else {
    return moment(new Date(dateTimeString)).tz(timezone).format();
  }
};

export { formatDate, formatDateTime, formatSimpleDate };
