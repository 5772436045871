import React from "react";
import { ButtonSettings, RaketaUIProvider } from "@raketa-cms/raketa-cms";

const shouldSetDefault = (value) =>
  !value ||
  (!value.link &&
    !value.label &&
    !value.id &&
    !value.type &&
    !value.target &&
    !value.rel);

class ButtonEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: shouldSetDefault(props.value)
        ? { ...ButtonSettings.defaults, label: "", link: "" }
        : props.value,
    };
  }

  render() {
    const { name, label, hint } = this.props;
    const { value } = this.state;

    return (
      <RaketaUIProvider>
        <div className="field-pannel form-group">
          <ButtonSettings
            label={label}
            onChange={(value) => this.setState({ value })}
            value={value}
          />

          {hint && (
            <div
              className="help-block"
              dangerouslySetInnerHTML={{ __html: hint }}
            />
          )}

          <input type="hidden" name={name} value={JSON.stringify(value)} />
        </div>
      </RaketaUIProvider>
    );
  }
}

ButtonEditor.defaultProps = {
  label: "Button",
};

export default ButtonEditor;
