import React from "react";
import { ButtonSettings, TextArea } from "@raketa-cms/raketa-cms";

class ButtonSettingsWithDescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: this.props.value,
    };
  }

  updateButtonSettings(value) {
    this.setState({ settings: value }, () => {
      const { onChange } = this.props;
      const { settings } = this.state;
      onChange(settings);
    });
  }

  updateDescription(value) {
    const { settings } = this.state;
    const newSettings = Object.assign({}, settings, { description: value });

    this.setState({ settings: newSettings }, () => {
      const { onChange } = this.props;
      const { settings } = this.state;
      onChange(settings);
    });
  }

  handleChange(type, value) {
    type === "buttonSettings" && this.updateButtonSettings(value);
    type === "description" && this.updateDescription(value);
  }

  render() {
    const { label } = this.props;
    const { settings } = this.state;

    return (
      <div className="btn-settings-description">
        <ButtonSettings
          value={settings}
          label={label}
          onChange={(settings) => this.handleChange("buttonSettings", settings)}
        />

        <TextArea
          label="Description"
          onChange={(description) =>
            this.handleChange("description", description)
          }
          value={settings.description}
        />
      </div>
    );
  }
}

const DEFAULTS = {
  label: "Button",
  link: "#",
  id: "",
  type: "primary",
  target: "_self",
  rel: "follow",
  sub_line: "",
};

ButtonSettingsWithDescription.defaultProps = {
  label: "Button",
  value: DEFAULTS,
};

ButtonSettingsWithDescription.defaults = DEFAULTS;

export default ButtonSettingsWithDescription;
