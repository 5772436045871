import React from "react";
import ItemsSection from "./ItemsSection";

const filterItems = (pricingType, items) => {
  return items.filter((item) => item.pricing_type === pricingType);
};

const PricelistItems = ({
  pricingTypes,
  items,
  itemTemplate,
  namePrefix,
  labels,
  currencies,
}) => (
  <div className="pricelist-items">
    {pricingTypes.map((pricingType, idx) => (
      <ItemsSection
        key={idx}
        pricingType={pricingType}
        namePrefix={namePrefix}
        itemTemplate={itemTemplate}
        items={filterItems(pricingType, items)}
        labels={labels}
        currencies={currencies}
      />
    ))}
  </div>
);

export default PricelistItems;
