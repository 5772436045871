class Cookie {
  static setCookie(name, value, days, domain) {
    let expires;

    if (days) {
      const date = new Date();

      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    if (domain) {
      document.cookie =
        name + "=" + value + expires + `;domain=${domain};path=/`;
    } else {
      document.cookie = name + "=" + value + expires + "; path=/";
    }
  }

  static getCookie(name, defaultValue = null) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return defaultValue;
  }

  static deleteCookie(name) {
    Cookie.setCookie(name, "", -1);
  }
}

export default Cookie;
