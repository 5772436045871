import React from "react";
import AutoCompleteSelect from "./AutocompleteSelect";

const FeaturedPricingCardAutocomplete = ({
  value,
  label,
  error,
  name,
  options,
}) => {
  const [cardId, setCardId] = React.useState(value);

  return (
    <React.Fragment>
      <AutoCompleteSelect
        label={label}
        options={options}
        placeholder="Start typing..."
        errorMessage={error}
        value={cardId}
        required
        onChange={(newValue) => setCardId(newValue || "")}
      />

      <input type="hidden" name={name} defaultValue={cardId} />
    </React.Fragment>
  );
};

export default FeaturedPricingCardAutocomplete;
