import React from "react";
import {
  PAGES,
  GalleriesStrip,
  GalleryStipPlaceholder,
} from "@chaos/website-frontend";
const { GalleryFilterStrip } = PAGES;

function updateUrl(selected) {
  const url = new URL(window.location);

  updateParam(url.searchParams, selected, "product");
  updateParam(url.searchParams, selected, "industry");
  updateParam(url.searchParams, selected, "host_platform");

  history.pushState(null, null, url.toString());
}

function isFilterEmpty(filter) {
  return (
    filter.product?.length === 0 &&
    filter.industry?.length === 0 &&
    filter.host_platform?.length === 0
  );
}

function prepareFilter(initialFilter) {
  return {
    product: (initialFilter.product || "").split(",").filter(Boolean),
    industry: (initialFilter.industry || "").split(",").filter(Boolean),
    host_platform: (initialFilter.host_platform || "")
      .split(",")
      .filter(Boolean),
  };
}

function updateParam(searchParams, selected, key) {
  const value = selected[key].map((item) => item.slug);

  if (value.length > 0) {
    searchParams.set(key, filterItemString(selected, key));
  } else {
    searchParams.delete(key);
  }
}

function filterItemString(filter, key) {
  return filter[key].map((item) => item.slug).join(",");
}

const useGalleriesFetch = (filter) => {
  const [loading, setLoading] = React.useState(!isFilterEmpty(filter));
  const [list, setList] = React.useState([]);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (filter.product || filter.host_platform || filter.industry) {
      const sp = new URLSearchParams();

      sp.set("product", filterItemString(filter, "product"));
      sp.set("industry", filterItemString(filter, "industry"));
      sp.set("host_platform", filterItemString(filter, "host_platform"));

      setLoading(true);
      setError(null);
      fetch(`/api/galleries/search?${sp.toString()}`)
        .then((r) => r.json())
        .then((d) => {
          setList(d);
          setLoading(false);
          setError(null);
        })
        .catch((e) => {
          setError(e);
          setList([]);
          setLoading(false);
        });
    }
  }, [filter]);

  return { loading, list, error };
};

const Galleries = ({ context, contentId }) => {
  const [filter, setFilter] = React.useState(
    prepareFilter(context.filter || {})
  );

  const { loading, list, error } = useGalleriesFetch(filter);

  return (
    <React.Fragment>
      <GalleryFilterStrip
        disabled={loading}
        onChange={(selected) => {
          updateUrl(selected);
          setFilter(selected);

          if (isFilterEmpty(selected)) {
            document.getElementById(contentId).style.display = "block";
          } else {
            document.getElementById(contentId).style.display = "none";
          }
        }}
        {...{ ...context, filter: filter }}
      />

      {!isFilterEmpty(filter) && (
        <GalleryStipPlaceholder
          thumbsCount={24}
          ready={loading}
          realComponent={() => (
            <div className="gallery-strip-wrapper">
              <GalleriesStrip galleries={list} />
            </div>
          )}
        />
      )}
    </React.Fragment>
  );
};

const CONTENT_ID = "cms-content";

const GalleryLayout = ({ page, children, ...rest }) => {
  return (
    <>
      {page.layout === "gallery" && (
        <Galleries
          context={rest.gallery_layout_context || {}}
          contentId={CONTENT_ID}
        />
      )}

      <div
        id={CONTENT_ID}
        style={{
          display: isFilterEmpty(
            prepareFilter(rest.gallery_layout_context?.filter || {})
          )
            ? "block"
            : "none",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default GalleryLayout;
