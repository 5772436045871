import React, { useState } from "react";
import { ListInput, ButtonSettings } from "@raketa-cms/raketa-cms";

const Button = ({ idx, settings, onChangeItem }) => (
  <div>
    <ButtonSettings
      label={`Button ${idx + 1}`}
      onChange={(value) => onChangeItem("button", value)}
      value={settings.button}
    />
  </div>
);

const ButtonsEditor = ({ value, name, label }) => {
  const [items, setItems] = useState(value);
  return (
    <div className="field-pannel form-group">
      <label className="control-label">{label}</label>
      <ListInput
        listItem={(settings, onChangeItem, idx) => (
          <Button idx={idx} settings={settings} onChangeItem={onChangeItem} />
        )}
        primaryField="button.label"
        onChangeList={(_, newItems) => setItems(newItems)}
        items={items}
        template={{ id: "", button: ButtonSettings.defaults }}
      />

      <input type="hidden" name={name} value={JSON.stringify(items)} />
    </div>
  );
};

export default ButtonsEditor;
