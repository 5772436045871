import React from "react";
import { LinkSettings, RaketaUIProvider } from "@raketa-cms/raketa-cms";

const initalValue = (value) => {
  if (value && value.link) {
    return value;
  }

  return LinkSettings.defaults;
};

const LinkInput = ({ name, label, error, value: defaultValue }) => {
  const [value, setValue] = React.useState(initalValue(defaultValue));

  return (
    <RaketaUIProvider>
      <div className={`field-pannel form-group ${error ? "has-error" : ""}`}>
        <LinkSettings
          label={label}
          onChange={(newValue) => setValue(newValue)}
          value={value}
        />

        <input type="hidden" name={name} value={JSON.stringify(value)} />

        {error && <span className="help-block">{error}</span>}
      </div>
    </RaketaUIProvider>
  );
};

LinkInput.defaultProps = {
  label: "Link",
};

export default LinkInput;
