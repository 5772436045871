import React from "react";

function Opened(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 14L7.5 11.1C7.66307 10.7761 7.91112 10.5027 8.21761 10.3089C8.5241 10.1152 8.8775 10.0084 9.24 10H20M20 10C20.3055 9.99946 20.6071 10.0689 20.8816 10.2031C21.1561 10.3372 21.3963 10.5325 21.5836 10.7739C21.7709 11.0152 21.9004 11.2963 21.9622 11.5955C22.024 11.8948 22.0164 12.2042 21.94 12.5L20.4 18.5C20.2886 18.9316 20.0362 19.3135 19.6829 19.5853C19.3296 19.857 18.8957 20.003 18.45 20H4C3.46957 20 2.96086 19.7893 2.58579 19.4142C2.21071 19.0391 2 18.5304 2 18V5C2 4.46956 2.21071 3.96086 2.58579 3.58578C2.96086 3.21071 3.46957 3 4 3H7.9C8.23449 2.99672 8.56445 3.07739 8.8597 3.23462C9.15495 3.39185 9.40604 3.62062 9.59 3.9L10.4 5.1C10.5821 5.37653 10.83 5.60352 11.1215 5.7606C11.413 5.91768 11.7389 5.99994 12.07 6H18C18.5304 6 19.0391 6.21071 19.4142 6.58578C19.7893 6.96086 20 7.46956 20 8V10Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Opened;
