import React from "react";
import AdminModal from "components/admin/Modal/Modal";
import AdminModalHeader from "components/admin/Modal/ModalHeader";
import AdminModalBody from "components/admin/Modal/ModalBody";
import AdminModalFooter from "components/admin/Modal/ModalFooter";
import ImageManager from "managers/image-manager";
import Cookie from "Cookie";

const getImageName = (image) => image.src.split("/").slice(-1)[0].split("?")[0];

const getImageSrc = (image) => {
  const filename = getImageName(image);

  if (filename.endsWith(".svg") || filename.endsWith(".gif")) {
    return image.src;
  }

  return image.thumbSrc;
};

class ImagePicker extends React.Component {
  constructor(props) {
    super(props);

    let image = {
      id: this.props.image_id,
      thumbSrc: this.props.image_thumb,
      src: this.props.image_full,
    };

    this.state = {
      open: false,
      images: [],
      selected_image: image,
      new_image: image,
      lastUsedLibrary: Cookie.getCookie("lastUsedLibrary", null),
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelectImage = this.handleSelectImage.bind(this);
    this.handleChooseImage = this.handleChooseImage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleClearImage = this.handleClearImage.bind(this);
  }

  componentDidMount() {
    var lastUsedLibrary = this.getLastUsedLibrary();
    var filter =
      lastUsedLibrary != null ? { image_library_id: lastUsedLibrary } : {};
    this.loadImages(filter);
  }

  getLastUsedLibrary() {
    return Cookie.getCookie("lastUsedLibrary", null);
  }

  loadImages(filter) {
    // add restrictions
    if (this.props.width) {
      filter.width = this.props.width;
      filter.height = this.props.height;
    }

    var self = this;
    ImageManager.all("/admin/images", { f: filter }, (images) =>
      self.setState({ images: images })
    );
  }

  hasImage() {
    return this.state.selected_image.id;
  }

  handleOpenModal() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleSelectImage(index) {
    const image = this.state.images[index];
    const new_image = {
      id: image.id,
      src: image.asset_url,
      thumbSrc: image.thumb_url,
    };

    this.setState({ new_image: new_image });
  }

  handleClearImage() {
    this.setState({ selected_image: "" });
    if (this.props.onChangeImage) this.props.onChangeImage("");
  }

  submitForm() {
    let title = this.refs.title.value.trim();
    let library_id = this.refs.library.value;

    let filter = {};
    if (title.length > 0) filter.title = title;
    if (library_id != "") {
      filter.image_library_id = library_id;
      Cookie.setCookie("lastUsedLibrary", library_id);
    }

    this.loadImages(filter);
  }

  handleChooseImage() {
    this.setState({ selected_image: this.state.new_image, open: false });
    if (this.props.onChangeImage)
      this.props.onChangeImage(this.state.new_image);
  }

  renderPreview() {
    const filename = getImageName(this.state.selected_image);
    const source = getImageSrc(this.state.selected_image);

    return (
      <div className="admin-thumb">
        <img
          src={source}
          className="preview"
          title={filename}
          onClick={this.handleOpenModal}
        />
      </div>
    );
  }

  renderLibrarySelect() {
    let nodes = this.props.libraries.map((item, idx) => (
      <option key={idx} value={item.id}>
        {item.name}
      </option>
    ));

    return (
      <select
        ref="library"
        className="form-control"
        onChange={this.submitForm}
        defaultValue={this.getLastUsedLibrary()}
      >
        <option value="">All Libraries</option>
        {nodes}
      </select>
    );
  }

  renderImages() {
    let self = this;
    let nodes = this.state.images.map((item, idx) => {
      let classes =
        item.id == self.state.new_image.id
          ? "admin-thumb image-item selected"
          : "admin-thumb image-item";

      return (
        <div
          className={classes}
          key={item.id}
          onClick={self.handleSelectImage.bind(null, idx)}
          onDoubleClick={self.handleChooseImage}
        >
          <img src={item.thumb_url} title={item.title} alt={item.title} />
          <div className="image-title">{item.title}</div>
        </div>
      );
    });

    return (
      <div className="images-list">
        {nodes.length > 0 ? nodes : <p>No results.</p>}
      </div>
    );
  }

  renderClearButton() {
    if (this.state.selected_image.id != undefined) {
      return (
        <button
          type="button"
          className="btn btn-default"
          onClick={this.handleClearImage}
        >
          Clear image
        </button>
      );
    }
  }

  renderSearchForm() {
    return (
      <div className="search-form">
        <div className="form-group">{this.renderLibrarySelect()}</div>
        <div className="form-group">
          <input
            type="text"
            ref="title"
            placeholder="Search by title..."
            className="form-control"
            onKeyUp={this.submitForm}
          />
        </div>
      </div>
    );
  }

  renderModal() {
    return (
      <AdminModal open={this.state.open}>
        <AdminModalHeader title="Select Image" onClose={this.handleClose} />

        <AdminModalBody>
          {this.renderSearchForm()}
          {this.renderImages()}
        </AdminModalBody>

        <AdminModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.handleChooseImage}
          >
            Select image
          </button>
          <span className="or">or</span>
          <button
            type="button"
            className="btn btn-link"
            onClick={this.handleClose}
          >
            Cancel
          </button>
        </AdminModalFooter>
      </AdminModal>
    );
  }

  render() {
    const buttonLabel = this.hasImage() ? "Change image..." : "Select image...";
    const { empty_input } = this.props;
    const emptyInputValue = empty_input === undefined ? "" : empty_input;

    return (
      <div className="image-picker">
        {this.hasImage() ? this.renderPreview() : ""}

        <br />
        <button
          type="button"
          className="btn btn-default"
          onClick={this.handleOpenModal}
        >
          {buttonLabel}
        </button>

        {this.renderClearButton()}

        <br />
        <br />
        <input
          type="hidden"
          id="image_id"
          name={this.props.name}
          value={
            this.state.selected_image.id === undefined
              ? emptyInputValue
              : this.state.selected_image.id
          }
        />

        {this.renderModal()}
      </div>
    );
  }
}

export default ImagePicker;
