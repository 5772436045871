import React from 'react';

const FileInput = ({ label, name, error, value }) => (
  <div className={`form-group file ${error ? 'has-error' : ''}`}>
    {label &&
      <label className="control-label">{label}</label>
    }
    <input
      className="form-control file"
      type="file"
      name={name}
    />

    {error && <span className="help-block">{error}</span>}
  </div>
);


export default FileInput;
