import React from 'react';
import A from 'components/app/A';
import FieldsDatePicker from 'components/admin/Fields/DatePicker/DatePicker';

class ProductCertifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            certificates: this.props.certificates,
            products: this.props.products,
            date: this.props.date || '',
        };


        this.handleCertificateDelete = this.handleCertificateDelete.bind(this);
        this.handleAddCertificate = this.handleAddCertificate.bind(this);
    }

    hasVersion() {
        return this.props.hasVersion == true;
    }

    hasCertificate(certificate) {
        return this.state.certificates.some((c) =>
            certificate.product_id == c.product_id && certificate.version == c.version
        );
    }

    resetForm() {
        this.refs.product.value = '';
        if (this.refs.version) this.refs.version.value = '';
    }

    certificateIsValid(certificate) {
        if (this.hasVersion()) {
            return certificate.product_id != '' && certificate.version != '' && certificate.date != '';
        } else {
            return certificate.product_id != '' && certificate.date != '';
        }
    }

    handleAddCertificate() {
        let certificate = {
            product_id: this.refs.product.value.trim(),
            product_name: this.refs.product.selectedOptions[0].text,
            date: this.state.date,
        };

        if (this.hasVersion()) certificate.version = this.refs.version.value.trim();
        if (this.hasCertificate(certificate) || !this.certificateIsValid(certificate)) return;

        let certificates = this.state.certificates;
        certificates.push(certificate);
        this.setState({ certficates: certificates });

        this.resetForm();
    }

    handleCertificateDelete(e) {
        if (!confirm('Are you sure? ')) return;

        let idx = e.target.dataset.product;

        let newCertificates = this.state.certificates.filter((_, index) => idx != index);

        this.setState({ certificates: newCertificates });
    }

    renderProductSelect() {
        let productNodes = this.props.products.map((product) =>
            (<option disabled={product.status === "draft"} key={product.id} value={product.id}>{product.name}</option>)
        );

        return (
            <select ref="product" className="form-control">
                <option value="">Select product...</option>
                {productNodes}
            </select>
        );
    }

    handleChangeDate(value) {
        this.setState({ date: value });
    }


    formatDate(str_date) {
        if (!str_date) return null;

        let parts = str_date.split('-');
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        let month_names = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        return month_names[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
    }

    renderCertificateList() {
        let self = this;

        let colspan = this.hasVersion() ? 4 : 3;
        let noResults = (
            <tr>
                <td colSpan={colspan}>Still no certifications.</td>
            </tr>
        );

        let certificateNodes = this.state.certificates.map((certificate, idx) =>
            (
                <tr key={idx}>
                    <td>{certificate.product_name}</td>
                    {self.hasVersion() ? (<td>{certificate.version}</td>) : null}
                    <td>{self.formatDate(certificate.date)}</td>
                    <td>
                        <A href="javascript:;" data-product={idx} onClick={self.handleCertificateDelete}>Delete</A>
                        <input type="hidden" name={'certificates[' + idx + '][product_id]'} value={certificate.product_id} />
                        <input type="hidden" name={'certificates[' + idx + '][version]'} value={certificate.version} />
                        <input type="hidden" name={'certificates[' + idx + '][date]'} value={certificate.date} />
                    </td>
                </tr>
            )
        );

        return (
            <table className="table table-condensed table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Product</th>
                        {this.hasVersion() ? (<th>Version</th>) : null}
                        <th>{this.props.date_title_label || 'Date'}</th>
                        <th style={{ width: '50px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.certificates.length > 0 ? certificateNodes : noResults}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <div className="form-group product-certifications">
                <h5>Product Certificates</h5>
                <div className="list">
                    {this.renderCertificateList()}
                </div>

                <h5>Add Certificate</h5>
                <div className="form-inline">
                    {this.renderProductSelect()}
                    {this.hasVersion() ? (<input ref="version" type="text" className="form-control version" placeholder="Version..." />) : ''}
                    <div className="date">
                        <FieldsDatePicker
                            value={this.state.date}
                            onChange={(value) => this.handleChangeDate(value)}
                            placeholderText={this.props.date_label + '...'}
                        />
                    </div>

                    <button type="button" className="btn btn-sm btn-success" onClick={this.handleAddCertificate}>Add certificate</button>
                </div>
            </div>
        );
    }
}

ProductCertifications.defaultProps = { date_label: 'Date' };

export default ProductCertifications;
