import React from "react";

class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener(
      "keydown",
      (e) => {
        if (this.modal) {
          this.handleEscKey(e);
        }
      },
      false
    );
  }

  handleEscKey(e) {
    if (e.keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const {
      title,
      open,
      onClose,
      children,
      className,
      modalWrapperClass,
      titleAlign,
    } = this.props;

    return (
      <div
        className={`cg-modal ${modalWrapperClass ? modalWrapperClass : ""} ${
          open === true ? "modal-show" : ""
        }`}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        <div className={`modal-cnt ${className !== "" ? className : ""}`}>
          {title && (
            <div className="modal-title-wrapper">
              <h3
                className={`modal-title ${
                  titleAlign === "left" ? "text-left" : "text-center"
                }`}
              >
                {title}
              </h3>
            </div>
          )}

          <button
            className="icon-close close"
            onClick={() => onClose()}
          ></button>

          {children}
        </div>

        <div className="modal-overlay" onClick={() => onClose()} />
      </div>
    );
  }
}

Modal.defaultProps = {
  className: "",
  titleAlign: "center",
};

export default Modal;
