import React from "react";
import { ADMIN_LIBRARY } from "@chaos/website-frontend";

const options = Object.keys(ADMIN_LIBRARY)
  .map((widget) => ({
    label: ADMIN_LIBRARY[widget].Config.title,
    value: widget,
  }))
  .sort((a, b) => a.label.localeCompare(b.label));

const WidgetsSelect = ({ label, name, error, value }) => (
  <div className={`form-group select ${error ? "has-error" : ""}`}>
    {label && <label className="control-label select">{label}</label>}

    <select name={name} defaultValue={value} className="form-control select">
      <option value=""></option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>

    {error && <span className="help-block">{error}</span>}
  </div>
);

export default WidgetsSelect;
