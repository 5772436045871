import React from 'react';
import FieldsTextInput from 'components/shared/Fields/TextInput/TextInput';

const ArticleResult = ({ article, onClick }) => (
  <div className="page-result" onClick={() => onClick(article)}>
    {article.title}
  </div>
);

const ArticleResults = ({ articles, onClick }) => {
  if (articles.length == 0) return (<div></div>);

  return (
    <div className="page-results">
      {articles.map((article) => (<ArticleResult key={article.id} article={article} onClick={onClick} />))}
    </div>
  );
}

const ArticlePreview = ({ article, onClear }) => {
  if (article.title == '') {
    return (
      <div className="page-preview text-muted empty-state">
        <strong>No article attached</strong><br />
        <span>Use the search above to select an Article. </span>
      </div>
    );
  }

  return (
    <div className="page-preview">
      <span className="clear-page" onClick={onClear}>&times;</span>

      <strong>{article.title}</strong><br />
      <span className="text-muted">{article.slug}</span>
    </div>
  );
};

const defaultArticle = () => ({ id: '', title: '' });

class ArticlePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      results: [],
      article: this.props.article || defaultArticle(),
    };
  }

  handleSearch(query) {
    this.setState({ query }, () => {
      if (query.length > 2) {
        $.get(this.props.search_url, { f: { title: query } }, (results) => this.setState({ results }));
      }
    });
  }

  handleSelectArticle(article) {
    this.setState({ article, results: [], query: '' });
  }

  handleClear() {
    if (confirm('Are you sure? ')) this.setState({ article: defaultArticle() })
  }

  render() {

    const { article, query, results } = this.state;
    const { field_name, label } = this.props;

    return (
      <div className="page-picker">
        <FieldsTextInput
          label={label || 'Article'}
          placeholder="Search articles..."
          value={query}
          onChange={(e) => this.handleSearch(e.target.value)}
        />

        <ArticleResults
          articles={results}
          onClick={(article) => this.handleSelectArticle(article)}
        />

        <ArticlePreview article={article} onClear={() => this.handleClear()} />

        <input type="hidden" name={field_name} value={article.id} />
      </div>
    );
  }
}

export default ArticlePicker;
