import React from "react";

function Add(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.1741 6.812C21.7028 6.28342 21.9998 5.56647 21.9999 4.81885C22 4.07124 21.7031 3.35421 21.1746 2.8255C20.646 2.29679 19.929 1.99971 19.1814 1.99962C18.4338 1.99952 17.7168 2.29642 17.1881 2.825L3.84206 16.174C3.60988 16.4055 3.43817 16.6905 3.34206 17.004L2.02106 21.356C1.99521 21.4425 1.99326 21.5343 2.01541 21.6219C2.03756 21.7094 2.08298 21.7892 2.14685 21.853C2.21073 21.9168 2.29068 21.9621 2.37821 21.9841C2.46575 22.0061 2.55762 22.004 2.64406 21.978L6.99706 20.658C7.31023 20.5628 7.59523 20.3921 7.82706 20.161L21.1741 6.812Z"
        stroke="#999999"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Add;
