import React from "react";
import { Img } from "@raketa-cms/raketa-cms";

const imgLoading = (value) => {
  if (value === "lazy") {
    return "lazy";
  }
  return "eager";
};

const Image = ({ src, loading, variant }) => (
  <React.Fragment>
    <div className="image-container">
      <Img loading={imgLoading(loading)} src={src} variant={variant} />
      {src.credits !== undefined && (
        <span className="credits">{src.credits}</span>
      )}
    </div>
  </React.Fragment>
);

export default Image;
