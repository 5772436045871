import React from "react";
import { MenuBuilder } from "@raketa-cms/raketa-menu-builder";
import { ThemeProvider } from "styled-components";
import { theme } from "@raketa-cms/raketa-mir";
import WIDGETS from "./widgets";

export default ({ name, label, value, error }) => {
  const [menu, setMenu] = React.useState({
    tab: 0,
    items: value,
  });

  return (
    <>
      <div className={`form-group ${error ? "has-error" : ""}`}>
        <ThemeProvider theme={theme}>
          <MenuBuilder
            widgets={WIDGETS}
            value={menu}
            onChange={(menu) => setMenu(menu)}
          />
        </ThemeProvider>

        <input type="hidden" name={name} value={JSON.stringify(menu.items)} />
        {error && <span className="help-block">{error}</span>}
      </div>
    </>
  );
};
