/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { TextArea } from '@raketa-cms/raketa-cms';

import Modal from 'components/ui/Modal';
import ModalContent from './ModalContent';

export default ({ value, label, onChange }) => {
  const [newValue, setValue] = useState(value);
  const [isModalVisible, setModalVisibility] = useState(false);

  return (
    <div className="markdown-input">
      <TextArea
        label={label}
        onChange={(value) => {
          onChange(value);
          setValue(value);
        }}
        value={newValue}
      />

      <button
        type="button"
        onClick={() => setModalVisibility(true)}
        className="modal-trigger"
      />

      <Modal
        open={isModalVisible}
        onClose={() => setModalVisibility(false)}
        modalWrapperClass="markdown-modal"
      >
        <ModalContent />
      </Modal>
    </div>
  );
};
