/* eslint-disable import/no-unresolved */
import React, { useState, useRef } from 'react';
import { EmbeddedVideo } from '@raketa-cms/raketa-cms';
import Img from 'components/ui/Img';
import A from 'components/app/A';

const OnVideoClick = (event, videoWrapper) => {
  event.preventDefault();

  if (videoWrapper) { videoWrapper.classList.toggle('show-video'); }
}

const GalleryVideo = ({ image }) => {
  const [play, setPlay] = useState(false);
  const video = useRef(null);

  return (
    <div className="embedded-video">
      <div ref={video} className="embedded-video-inner" onClick={(e) => { setPlay(true); OnVideoClick(e, video.current) }}>
        <A href="#" onClick={(e) => e.preventDefault()} className="full-image-anchor">
          <span className="play-btn icon-play" />

          <div className="full-image">
            <Img src={image.urls.gallery_image} variant="gallery_image" className="content" />
          </div>
        </A>

        {
          play &&
          <div className="full-video full-image">
            <EmbeddedVideo videoUrl={image.video_url} autoplay={play} />
          </div>
        }
      </div>
    </div >
  );
}

export default GalleryVideo;
