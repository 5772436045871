import React from 'react';
import Dropzone from './Dropzone';

class Dropbox extends React.Component {
  constructor(props) {
    super(props);

    this.onOpenClick = this.onOpenClick.bind(this);
  }

  onOpenClick() {
    this.refs.dropzone.open();
  }

  render() {
    return (
        <div className="dropbox">
          <Dropzone ref="dropzone" className="well dropzone" onDrop={ this.props.onDrop }>
            <div>
              <h5>Select files from your computer</h5>
              <small>or drag and drop here</small>
            </div>
          </Dropzone>

          <button type="button" className="btn btn-default" onClick={ this.onOpenClick }>
              { this.props.buttonLabel }
          </button>
        </div>
    );
  }
}

Dropbox.defaultProps = { buttonLabel: 'Select files...' };

export default Dropbox;
