import React from "react";

const global = typeof window !== "undefined" ? window : {};

const useMousePosition = () => {
  React.useEffect(() => {
    const updateMousePosition = (ev) => {
      global.rMp = { x: ev.clientX, y: ev.clientY };
      global.rMpt = new Date().getTime();
    };

    global.addEventListener("mousemove", updateMousePosition);

    return () => {
      global.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);
};

const useIntentToLeave = (fn) => {
  React.useEffect(() => {
    const updateLeave = (_ev) => {
      global.rPs = "out";
      fn();
    };

    const updateEnter = () => {
      global.rPs = "in";
    };

    document.documentElement.addEventListener("mouseleave", updateLeave);
    document.documentElement.addEventListener("mouseenter", updateEnter);

    return () => {
      document.documentElement.removeEventListener("mouseleave", updateLeave);
      document.documentElement.addEventListener("mouseenter", updateEnter);
    };
  }, [fn]);
};

function isMouseOutsideOfThreshold(coordinates, lastMoved, screenSize, opts) {
  const topThreshold = screenSize.height * opts.threshold;

  const now = new Date().getTime();

  return (
    coordinates.y < topThreshold &&
    now - parseInt(lastMoved) >= opts.delay &&
    global.rPs == "out"
  );
}

export default function ItlMonitor({
  onLeave,
  delay = 2000,
  threshold = 0.05,
}) {
  useMousePosition();
  useIntentToLeave(() => {
    setTimeout(() => {
      if (
        isMouseOutsideOfThreshold(
          global.rMp,
          global.rMpt,
          {
            height: global.innerHeight,
            width: global.innerWidth,
          },
          { delay, threshold }
        )
      ) {
        onLeave();
      }
    }, delay);
  });

  return null;
}
