import React from "react";
import SelectList from "components/admin/Fields/SelectList";

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selected || props.countries.map((c) => c.id),
    };
  }

  render() {
    const { label, name, countries } = this.props;
    const { selected } = this.state;

    return (
      <div>
        <SelectList
          label={label}
          options={countries}
          selected={selected}
          onChange={(selected) => this.setState({ selected })}
        />

        <input type="hidden" name={`${name}[]`} value="" />
        {selected.map((cid) => (
          <input key={cid} type="hidden" name={`${name}[]`} value={cid} />
        ))}
      </div>
    );
  }
}

CountrySelect.defaultProps = {
  label: "Countries",
};

export default CountrySelect;
